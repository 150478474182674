import { defineMessages } from "react-intl";

export const scope = "lumenisXVersions";

export default defineMessages({
	type: {
		id: `${scope}.type`,
		defaultMessage: "Type",
	},
	softVersion: {
		id: `${scope}.softVersion`,
		defaultMessage: "Soft Version",
	},
	uploadDate: {
		id: `${scope}.uploadDate`,
		defaultMessage: "Upload Date",
	},
	uploadedBy: {
		id: `${scope}.uploadedBy`,
		defaultMessage: "Uploaded By",
	},
	validType: {
		id: `${scope}.validType`,
		defaultMessage: "Valid Type",
	},
	upload: {
		id: `${scope}.upload`,
		defaultMessage: "Upload",
	},
	comments: {
		id: `${scope}.comments`,
		defaultMessage: "Comments",
	},
	version: {
		id: `${scope}.version`,
		defaultMessage: "Version",
	},
	uploadVersion: {
		id: `${scope}.uploadVersion`,
		defaultMessage: "Upload LumenisX Version",
	},
	valid: {
		id: '${scope}.valid',
		defaultMessage: 'Valid',
	},
	invalid: {
		id: '${scope}.invalid',
		defaultMessage: 'Invalid',
	},
	versionAlreadyExist:{
		id: `${scope}.versionAlreadyExist`,
		defaultMessage: 'Can not save, version already exist.',	
	}
});
