export default {
  getItem(key) {
    try {
      const serializedObjString = localStorage.getItem(key);
      if (serializedObjString === null) {
        return undefined;
      }
      return JSON.parse(serializedObjString);
    } catch (err) {
      return undefined;
    }
  },

  setItem(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      throw new Error("Save failed");
    }
  },

  removeItem(key) {
    localStorage.removeItem(key);
  }
};