/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */
import env from "../env";

let STG_CHINA_CONFIG = {
  API_URL: "https://api.staging.lumenisx.lumenis.com.cn",
  GOOGLE_ANALYTICS_TRACKER_ID: "",
  QLIK_URL: "about:blank",
  LOGIN_VIA_ADFS: env.REACT_APP_LOGIN_VIA_ADFS.toLowerCase() === "true",
  EVENT_VIEWER_URL: 'https://eventviewer.staging.lumenisx.lumenis.com.cn/setup',
  LOG_SUPPORT_VERSION: env.REACT_APP_MIN_LMX_VERSION_SUPPORT_LOGS,
  LOG_V2_SUPPORT_VERSION: env.REACT_APP_MIN_LMX_VERSION_SUPPORT_LOGS_V2,
  OTHER_REGION_URL: "https://web.staging.lumenisx.lumenis.com/",
  OTHER_REGION_ICON_FILE_PATH: "/global_icon.png",
  HIDE_LICENSES: env.REACT_APP_HIDE_LICENSES.toLowerCase() === "true",
  LICENSE_NEARING_EXPIRATION_DAYS: env.REACT_APP_LICENSE_NEARING_EXPIRATION_DAYS
};

export default STG_CHINA_CONFIG;
