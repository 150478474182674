/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js';
import env from './env';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
import history from 'utils/history';
import AppConfig from 'config/AppConfig';

import UserManagement from './libs/client-user-management';
import LocalStorage from './services/LocalStorage';
import 'sanitize.css/sanitize.css';


import App from 'containers/App';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

import configureStore from './configureStore';
import { translationMessages } from './i18n';
import { STORAGE_LOGIN_DATA_KEY } from 'containers/App/app.constants';

/*
//Used to discover redundant render cycles
if (process.env.NODE_ENV === 'development') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	const ReactRedux = require('react-redux');
	whyDidYouRender(React, {
		include: [/Users/],
		collapseGroups: true,
		trackExtraHooks: [
			[ReactRedux, 'useSelector']
		]
		// trackAllPureComponents: true,
	});
}
*/

const { component: AppComponent } = App;

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
	document.body.classList.add('fontLoaded');
});

// Create redux store with session state and history
let initialState = {};
try {
	//TODO fix UMS package
	//sessionStorage - workaround while using UMS package
	//localStorage - UMS package basically does this in init(), but in an async way and not returning a promise, which causes the UI to render incorrectly (isLoggedIn is false when should be true)
	const loginDataString = sessionStorage.getItem(STORAGE_LOGIN_DATA_KEY);
	if (loginDataString) {
		const loginData = JSON.parse(loginDataString);
		if (loginData) {
			initialState = { userManagementReducer: loginData };
			// initialState.login = {data: loginData};
		}
	}
} catch (err) {
	console.error(err);
}

const store = configureStore(initialState, history);

//init user management service
const umsBaseURL = `${AppConfig.API_URL}/ums/v1`;
const onTokenFailed = () => {
	history.push('/login');
};

UserManagement.init({ store, umsBaseURL, onTokenFailed, localStorage: LocalStorage });

const MOUNT_NODE = document.getElementById('root');

const render = messages => {
	ReactDOM.render(
		<Provider store={store}>
			<LanguageProvider messages={messages}>
				<ConnectedRouter history={history}>
					<AppComponent />
				</ConnectedRouter>
			</LanguageProvider>
		</Provider>,
		MOUNT_NODE,
	);
};

/*
HMR DISABLE
if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./i18n', 'containers/App'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		render(translationMessages);
	});
}
*/

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
	new Promise(resolve => {
		resolve(import('intl'));
	})
		.then(() =>
			Promise.all([
				import('intl/locale-data/jsonp/en.js'),
				import('intl/locale-data/jsonp/de.js'),
			]),
		) // eslint-disable-line prettier/prettier
		.then(() => render(translationMessages))
		.catch(err => {
			throw err;
		});
} else {
	render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (env.NODE_ENV === 'production') {
	require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
