import React, { useEffect } from 'react';
import EntityGridToolbar from 'containers/Grid/components/EntityGridToolbar';
import _ from 'lodash';
import FormField from 'components/FormFactory/FormField';
import AlarmStateSelector from 'containers/AlarmStateSelector';

const AlarmsGridToolbar = (props) => {
	const {
		      setGridParams,
			  grid,
			  alarmStates,
        showAddNew = true,
        refreshGrid,
			  showModal,
			  onLoad
		  } = props;

	useEffect(() => { onLoad() }, []);

	const onFiltersChange = (filterValues) => {
		const { alarmStateOption } = filterValues;
		const filters = {};

		if (Number.isInteger(alarmStateOption)) {
			filters["state.id"] = alarmStateOption;
		}

		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			filters
		});
	};

	const onSearchChange = (value) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			search:     value
		});
	};

	const onResetFilters = (form) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			search:     '',
			filters:    {}
		});
	};

	const filterFormElements = (
		<>
			<FormField
				name="alarmStateOption"
				style={{ minWidth: 350 }}>
				<AlarmStateSelector 
					stateOptions={alarmStates}
					allowClear
				/>
			</FormField>
		</>
	);

	return (
		<EntityGridToolbar
			onResetFilters={onResetFilters}
			onFiltersChange={onFiltersChange}
      onSearchChange={onSearchChange}
      onRefresh={refreshGrid}
			filterFormElements={filterFormElements}
      		showRefreshButton={true}
		/>
	);
};

AlarmsGridToolbar.propTypes = {};

export default AlarmsGridToolbar;