import React from "react";
import PropTypes, { object } from "prop-types";
import { Button, Spin, Typography } from "antd";
import styled from "styled-components";
import messages from "../messages";
import {
  PUBLISH_SECURITY_VERSION_COMMAND_NAME,
  REMOTE_INSTALLATION_COMMAND_STATUS_NAME,
  DOWNLOADED_COMMAND_STATUS_NAME
} from "../../general/CommandsConstants";

const { Text } = Typography;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 200px;
  height: 32px;
`;

const SecurityPatchTab = (props) => {
  const { 
    device,
    latestCommand,
    updateCommand,
    isSecurityPatchRequestPending,
    intl 
  } = props;

  const { formatMessage } = intl;

  const renderVersion = () => {
    if (!latestCommand?.parameters?.securityVersionName) {
      return formatMessage(messages.noSecurityVersion);
    }
    return latestCommand.parameters.securityVersionName;
  };

  return (
    <Spin spinning={isSecurityPatchRequestPending} size="large">
      <Container>
        <ButtonContainer>
          <StyledButton
            type="primary"
            disabled={latestCommand?.status !== DOWNLOADED_COMMAND_STATUS_NAME}
            onClick={() => 
              updateCommand({
                deviceSerialNumber: device.deviceSerialNumber,
                deviceType: device.deviceType.type,
                commandType: PUBLISH_SECURITY_VERSION_COMMAND_NAME,
                customMessage: '',
                status: REMOTE_INSTALLATION_COMMAND_STATUS_NAME
              })
            }
          >
            {formatMessage(messages.installSecurityPatch)}
          </StyledButton>
        </ButtonContainer>
        <TextContainer>
          <Text strong>{formatMessage(messages.securityVersion)}: </Text>
          <Text>{renderVersion()}</Text>
        </TextContainer>
        <TextContainer>
          <Text strong>{formatMessage(messages.commandStatus)}: </Text>
          <Text>{latestCommand?.status ? formatMessage(messages[`status.${latestCommand.status}`]) : formatMessage(messages.noLatestCommand)}</Text>
        </TextContainer>
      </Container>
    </Spin>
  );
};

SecurityPatchTab.propTypes = {
  device: PropTypes.object.isRequired,
  latestCommand: object,
  updateCommand: PropTypes.func.isRequired,
  isSecurityPatchRequestPending: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default SecurityPatchTab;