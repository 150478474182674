import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'components/ErrorBoundary';
import styled from 'styled-components';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';

const Content = styled.div`
    height:25vh;
    font-weight: 500;
    padding: 10px;
    font-size: 1.1em;
`;

const ActionButtons = styled.div`
  text-align: left;
  background-color: #001529;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
`;

const AcceptButton = styled(Button)`
`;

const DeclineButton = styled(Button)`
    margin-right: 8px;
    :active, :focus, :hover{
        color: red;
        border-color: red;
    }
`;

const HyperLink = styled.a`
    &, :hover{
    text-decoration: underline;
    }
`;

const AcceptedDiv = styled.div`
    display: flex;
    align-items: baseline;
`;


const ConsentContent = ({intl, downloadUrl, isAccepted, onAccept, logout, hideModal})=>{

	const _onDecline = () => {
        logout();
        hideModal();
	};



    return (
        <React.Fragment>
            <Content>
                <FormattedMessage
                    {...messages.lumenisxTermsOfUseContent}
                    values={{
                        termsOfUseLink: (
                        <HyperLink href={downloadUrl} target="_blank">
                        {intl.formatMessage(messages.termsOfUse)}
                        </HyperLink>
                        ),
                        br: <br/>,
                        userPrivacyNoticeLink: (
                        <HyperLink href={`${downloadUrl}#_100:2433`} target="_blank">
                        {intl.formatMessage(messages.userPrivacyNotice)}
                        </HyperLink>
                        ),
                    }}
                />
            </Content>
            <ActionButtons>
                <DeclineButton disabled={isAccepted} onClick={_onDecline}><FormattedMessage {...messages.decline} /></DeclineButton>
                <AcceptButton type="primary" onClick={onAccept} disabled={isAccepted}>
                    {isAccepted ? 
                        <AcceptedDiv> 
                            <FormattedMessage {...messages.accepted} />
                            &nbsp;
                            <CheckOutlined />
                        </AcceptedDiv>
                    : <FormattedMessage {...messages.accept} />}
                </AcceptButton>
            </ActionButtons>
        </React.Fragment>
    )
}


ConsentContent.propTypes = {
    downloadUrl: PropTypes.string,
    isAccepted: PropTypes.bool,
    onAccept: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    intl: PropTypes.object,
};

ConsentContent.defaultProps = {
    isAccepted: false,
};

const scope = 'ConsentForm';
const messages = defineMessages({
    accepted: {
      id: `${scope}.accepted`,
      defaultMessage: 'Accepted',
    },
    accept: {
      id: `${scope}.accept`,
      defaultMessage: 'Accept',
    },
    decline: {
      id: `${scope}.decline`,
      defaultMessage: 'Decline',
    },
    lumenisxTermsOfUseContent: {
        id: `${scope}.lumenisxTermsOfUseContent`,
        defaultMessage: 'By clicking on the “Login” button you confirm your agreement to be bound by the LumenisX {termsOfUseLink}.{br} If you are not a Lumenis employee, we also draw your specific attention to the {userPrivacyNoticeLink} contained therein.',
    },
    termsOfUse: {
        id: `${scope}.termsOfUse`,
        defaultMessage: 'Terms of Use',
    },  
    userPrivacyNotice: {    
        id: `${scope}.userPrivacyNotice`,
        defaultMessage: 'User Privacy Notice',
    },  
  });

export default injectIntl(withErrorBoundary(ConsentContent));