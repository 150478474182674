export const STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  GENERAL_ERROR: 'GENERAL_ERROR',
  TOKEN_NOT_VALID: 'TOKEN_NOT_VALID',
}

const TOKEN_OPERATION_STATUS_CODE = Object.freeze({
  TOKEN_NOT_VALID: 'TOKEN_NOT_VALID',
});

const errorCodeToScreenStatusMapper = {
  [TOKEN_OPERATION_STATUS_CODE.TOKEN_NOT_VALID]: STATUS.TOKEN_NOT_VALID,
}

export const getScreenStatusByErrorCode = (errCode) => errorCodeToScreenStatusMapper[errCode] || STATUS.GENERAL_ERROR;
