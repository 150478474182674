import React from "react";
import PropTypes from "prop-types";
import FormFactory from "components/FormFactory";
import { Col, Row } from "antd";
import { withErrorBoundary } from "components/ErrorBoundary";
import TextAreaField from "components/FormFactory/fields/TextAreaField";
import TextField from "components/FormFactory/fields/TextField";
import FileBrowserField from "components/FormFactory/fields/FileBrowserField";
import commonMessages from "src/commonMessages";
import messages from "../messages";
import DeviceTypeTreeSelector from "containers/DeviceTypeTreeSelector/index";
import FormField from "components/FormFactory/FormField";
import InstallTypeSelector from "containers/InstallTypeSelector/index";

const validateMessages = {
	required: '%s is required',
	array: {
		max: '%s cannot contain more than %s file'
	}
};

const Form = FormFactory.create({
	name: "creatSwVersionsForm",
	onFieldsChange: (props, changedFields, allFields) => {
	},
	validateMessages,
});

class SwVersionsForm extends React.Component {
	static propTypes = {
		intl: PropTypes.object.isRequired,
		isCreatePending: PropTypes.bool,
	};

	onSubmit = (swVersionValues) => {
		this.props.createSwVersion(swVersionValues);
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	render() {
		const { isCreatePending, intl } = this.props;
		const submitButtonText = intl.formatMessage(commonMessages.save);
		const uploadText = intl.formatMessage(messages.upload);
		const versionText = intl.formatMessage(messages.version);
		const fileTypeText = intl.formatMessage(messages.fileType);
		const supportedVersionsText = intl.formatMessage(messages.supportedVersions);
		const installTypeText = intl.formatMessage(messages.installType);
		const commentsText = intl.formatMessage(messages.comments);
		const DeviceTypesText = intl.formatMessage(messages.deviceTypes);
		const deviceTypeNonSelectableText = intl.formatMessage(messages.deviceTypeNonSelectable);
		return (
			<Form
				submitButtonText={submitButtonText}
				hideSubmitButton={false}
				onSubmit={this.onSubmit}
				isLoading={isCreatePending}
				hideCancelButton={false}
				onCancel={this.onCancel}
			>
				<Row gutter={24}>
					<Col span={9}>
						<FormField name="deviceTypeFilter" label={DeviceTypesText} required style={{ minWidth: 350 }}>
							<DeviceTypeTreeSelector
								multiSelect={false}
								nonSelectableTreeLevels={[0]}
								nonSelectableMessage={deviceTypeNonSelectableText}
								allowClear={true}
							/>
						</FormField>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={9}>
						<FileBrowserField
							name="upload"
							label={uploadText}
							required
							disabled={false}
						/>
						<TextField
							name="version"
							label={versionText}
							required
							disabled={false}
							maxLength={36}
						/>
						<TextAreaField
							name="comments"
							label={commentsText}
							autoSize={{ minRows: 6, maxRows: 6 }}
							maxLength={256}
							disabled={false}
						/>
					</Col>
					<Col span={9}>
						<TextField
							name="fileType"
							label={fileTypeText}
							required
							disabled={false}
							maxLength={32}
						/>
						<TextField
							name="supportedVersions"
							label={supportedVersionsText}
							required
							disabled={false}
							maxLength={32}
						/>
						<FormField name="installTypeSelector" label={installTypeText} required style={{ minWidth: 100 }}>
							<InstallTypeSelector allowClear />
						</FormField>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default withErrorBoundary(SwVersionsForm);