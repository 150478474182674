import { call, put } from "redux-saga/effects";
import _ from "lodash";
import invariant from "invariant";
import { normalize } from "normalizr";
import { setEntities } from "containers/App/modules/entities/entities.utils";

/**
 * default response parser. can be injected from outside
 * @param response
 * @returns {*}
 */
const defaultResponseParser = (responseData) => responseData;
const defaultMetadataParser = (responseData) => responseData.metadata;

export function* api({
  apiFn,
  params,
  actions,
  parseSuccessResponseFn = defaultResponseParser,
  parseMetadataFn = defaultMetadataParser,
  schema,
}) {
  const [pendingAction, successAction, failureAction] = actions;
  yield put(pendingAction());

  invariant(apiFn, "BackendService function (apiFn) must be provided");

  invariant(
    actions && _.isArray(actions) && actions.length === 3,
    "appActions must be an array with 3 action creators in order: PENDING, SUCCESS, FAILURE"
  );

  try {
    const response = yield call(apiFn, params);

    const responseData = response?.data || response;
    const parsedData = parseSuccessResponseFn(responseData);
    let entities;
    if (schema) {
      entities = normalize(parsedData, schema);
      yield put(setEntities(entities));
    }
    const metadata = parseMetadataFn(responseData);
    const data = entities ? entities : parsedData;
    yield put(successAction({ data, metadata, requestParams: params }));
  } catch (err) {
    const errorCode = _.get(
      err,
      "response.data.code",
      _.get(err, "message", err)
    );
    const message = _.get(
      err,
      "response.data.message",
      _.get(err, "message", err)
    );
    let description = _.get(
      err,
      "response.data.messageWithCause",
      _.get(err, "response.data.message", err)
    );

    description = description || _.get(err, "response.data.message", err)
    yield put(failureAction({ errorCode, params, message, description }));
  }
}
