import ApiService from "../../../api-service";
import { ENDPOINTS, ENDPOINT_CREATORS } from "../constants/endPoints";
import { userDataChanged, resetUserData } from "../modules/actions";
import APP_CONFIG from "config/AppConfig";

const REDUCER_NAME = "userManagementReducer";
const USER_DATA_KEY = "UserService/USER_DATA_KEY";

// The next function is a wrapper function to any function that needs the store.
function storeNeeded(target, name, descriptor) {
  let oldFN = descriptor.value;

  descriptor.value = function (args) {
    if (this._storeRef === undefined) {
      throw new Error("Store was not found, did you set config?");
    }

    return oldFN.call(this, args);
  };
}

class UserManagement {
  init = ({ store, umsBaseURL, onTokenFailed, localStorage, timeout }) => {
    this._umsBaseURL = umsBaseURL;
    this.onTokenFailed = onTokenFailed;
    this._localStorage = localStorage;
    this._storeRef = store;

    this._apiService = ApiService.create({
      baseURL: umsBaseURL,
      refreshTokenResource: { baseURL: umsBaseURL, ...ENDPOINTS.REFRESH_TOKEN },
      tokenHandler: this,
      timeout: timeout,
    });

    this._loadUserDataFromStorage();
  };

  async selfRegistration({ email, firstName, lastName, password, phone }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: { ...ENDPOINTS.SELF_REGISTRATION },
        data: { email, firstName, lastName, password, phone },
      });

      return response.data;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  async login({ user, password, rememberMe }) {
    let response = await this.loginExternal({ user, password, rememberMe, endPoint: ENDPOINTS.LOGIN });
    return response;
  }

  @storeNeeded
  async loginExternal({ user, password, rememberMe, endPoint, providerName }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: { baseURL: this._umsBaseURL, ...endPoint },
        data: { email: user, password },
        params: { providerName }
      });

      await this.afterLogin({ loginResponse: response, rememberMe, email: user });
      return response.data;

    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  @storeNeeded
  async loginWithToken() {
    const accessToken = this._storeRef.getState()[REDUCER_NAME].accessToken;
    let endPoint = { ...ENDPOINTS.LOGIN_WITH_TOKEN };
    endPoint.headers = {
      ...endPoint.headers,
      Authorization: "Bearer " + accessToken
    };
    try {
      const response = await this._apiService.sendRequest({
        resource: endPoint
      });

      const userData = await this._localStorage.getItem(USER_DATA_KEY);
      const rememberMe = !!userData;

      await this.afterLogin({ loginResponse: response , rememberMe });

      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async afterLogin({ loginResponse, rememberMe, email }) {
    this._saveUserData({ rememberMe, userData: { email, ...loginResponse.data } });
    await this._loadPermissions();
  }

  async forgotPassword({ tenantID = "1", email }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: {
          ...ENDPOINTS.FORGOT_PASSWORD,
          uri: ENDPOINTS.FORGOT_PASSWORD.uri + tenantID,
        },
        data: { email }
      });

      return response;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  async resetPassword({ userId, forgotPasswordToken, password }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: { baseURL: this._umsBaseURL, ...ENDPOINTS.RESET_PASSWORD },
        additionalPath: userId,
        data: { forgotPasswordToken, password }
      });

      return response;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  async changePassword({ oldPassword, newPassword }) {
    try {
      const response = await this._apiService.sendRequest({
        resource: { baseURL: this._umsBaseURL, ...ENDPOINTS.CHANGE_USER_PASSWORD },
        data: { oldPassword, newPassword },
      });

      return response;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  async loginByPhoneNumber({ tenantID = "1", code, phone }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: {
          ...ENDPOINTS.LOGIN_BY_PHONE_NUMBER,
          uri: ENDPOINTS.LOGIN_BY_PHONE_NUMBER.uri + tenantID,
        },
        data: { code, phone }
      });

      return response;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  async selfRegistrationByPhoneNumber({ firstName, lastName, phone }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: {
          ...ENDPOINTS.SELF_REGISTRATION_BY_PHONE_NUMBER,
        },
        data: { firstName, lastName, phone }
      });

      return response;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  async generateLoginCode({ phone }) {
    try {
      let response = await this._apiService.sendRequest({
        resource: {
          ...ENDPOINTS.GENERATE_LOGIN_CODE,
        },
        data: { phone }
      });

      return response;
    } catch (error) {
      const message = error.response !== undefined ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  @storeNeeded
  async logout() {
    const refreshToken = this._storeRef.getState()[REDUCER_NAME].refreshToken;
    let endPoint = { ...ENDPOINTS.LOGOUT };
    endPoint.headers = {
      ...endPoint.headers,
      Authorization: "Bearer " + refreshToken
    };
    try {
      const response = await this._apiService.sendRequest({
        resource: endPoint,
        data: null
      });

      return response.data;
    } catch (response) {
      throw new Error(response);
    } finally {
      try {
        this._localStorage.removeItem(USER_DATA_KEY);
        this._storeRef.dispatch(resetUserData());
      } catch (error) {

      }
    }
  }

  async forgotPassword(email) {
    try {
      const response = await this._apiService.sendRequest({
        resource: ENDPOINTS.FORGOT_PASSWORD,
        data: { email }
      });

      return response.data;
    } catch (error) {
      return error.response !== undefined ? error.response.data.code : 'Connection Refused';
    }
  }


  async verifyEmail({ userId, verifyEmailToken }) {
    try {
      const response = await this._apiService.sendRequest({
        resource: ENDPOINT_CREATORS.VERIFY_EMAIL(userId),
        params: { token: verifyEmailToken }
      });

      return response.data;
    } catch (error) {
      const message = error.response ? error.response.data.code : 'Connection Refused';
      throw new Error(message);
    }
  }

  @storeNeeded
  getUserData() {
    let userData = this._storeRef.getState()[REDUCER_NAME];
    if (userData.accessToken !== null) {
      return Promise.resolve(userData);
    }

    return Promise.resolve(null);
  }

  @storeNeeded
  getToken() {
    const token = this._storeRef.getState()[REDUCER_NAME].accessToken;
    return Promise.resolve(token);
  }

  @storeNeeded
  isUserLoggedIn() {
    const token = this._storeRef.getState()[REDUCER_NAME].accessToken;
    return token ? true : false;
  }

  @storeNeeded
  getRefreshToken() {
    const refreshToken = this._storeRef.getState()[REDUCER_NAME].refreshToken;
    return Promise.resolve(refreshToken);
  }

  @storeNeeded
  async setToken(token) {
    this._storeRef.dispatch(userDataChanged({ accessToken: token }));
    const userData = await this._localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      userData.accessToken = token;
      this._localStorage.setItem(USER_DATA_KEY, userData);
    }
  }

  @storeNeeded
  _saveUserData({ rememberMe, userData }) {
    if (rememberMe) {
      this._localStorage.setItem(USER_DATA_KEY, userData);
    }

    this._storeRef.dispatch(userDataChanged({ ...userData }));
  }

  @storeNeeded
  async _loadUserDataFromStorage() {
    const userData = await this._localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      this._storeRef.dispatch(userDataChanged({ ...userData }));
    }
  }

  @storeNeeded
  async _loadPermissions() {
    const response = await this._apiService.sendRequest({ resource: { ...ENDPOINTS.PERMISSIONS, baseURL: APP_CONFIG.API_URL + "/ums/v2/users/self" } });
    this._storeRef.dispatch(userDataChanged({ userPermissions: response.data.permissions }));
  }

  @storeNeeded
  hasPermission(permissionName) {
    const permissions = this._storeRef.getState()[REDUCER_NAME].userPermissions;
    return permissions.includes(permissionName);
  }
}

export default new UserManagement({});
