import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: ${props => !props.size ? '2em auto' : 'auto' };
  width: ${props => !props.size ? '40px' :
                     props.size === 'sm' ? '20px' : '60px'};
  height: ${props => !props.size ? '40px' :
                     props.size === 'sm' ? '20px' : '60px'};
  position: relative;
`;

Wrapper.propTypes = {
  size: PropTypes.oneOf(['lg', 'md', 'sm'])
}

export default Wrapper;
