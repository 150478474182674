import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import {Button, Typography} from 'antd';
const { Title } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column; 
`;

const ErrorDescription = styled(Title)
  .attrs({ level: 3})`
  &.ant-typography {
    color: #fff;
  }
`;

const ErrorComponent = ({description, buttonText, onClick})=> {
  return (
    <Wrapper>
      <ErrorDescription>{description}</ErrorDescription>
      {
        buttonText &&
        onClick &&
        <Button type="primary" onClick={onClick}>{buttonText}</Button>
      }

    </Wrapper>
  )
}

ErrorComponent.propTypes = {
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}

export default ErrorComponent;
