import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UploadFile from './UploadFile'
import { Modal } from 'antd';
import styled from 'styled-components';
import { ACTION_STATUS } from 'containers/App/app.constants';

const ModalHeader = styled.div`
  font-size: 2.5em;
  margin-bottom: 1.5em;
`;

const ModalBody = styled.div`
  font-size: 1.3em;
  line-height: 2em;
`;

const ModalFooter = styled.div`
  margin-top: 6em;
  font-size: 1.3em;
  text-decoration: underline;
  color: #40B0FF;

  &:hover {
    cursor: pointer;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const V2CTab = (props) => {
  const { updateV2CStatus, device } = props;
  const [modalVisible, setModalVisible] = useState(false);
  
  const { manufacturerId, deviceSerialNumber } = device;
  const deviceType = device.deviceType.type;

  const onModalCancel = () => setModalVisible(false);

  useEffect(() => {
    if(updateV2CStatus === ACTION_STATUS.SUCCESS) {
      setModalVisible(true)
    } else {
      setModalVisible(false)
    }
  }, [updateV2CStatus])

  return (
    <>
      <Modal onCancel={onModalCancel}
			 visible={modalVisible}
       maskClosable={true}
       closable={false}
			 centered={true}
       keyboard={false}
       footer={null}
			 >
				<ModalWrapper>
          <ModalHeader>
            License Was Sent
          </ModalHeader>
          <ModalBody>
            {`The license was sent to device ${deviceType}`}
            <br />
            V2C list will be updated once received by the device
          </ModalBody>
          <ModalFooter onClick={onModalCancel}>
            Close
          </ModalFooter>
        </ModalWrapper>
			</Modal>
      <UploadFile
        updateV2C={(values) => props.updateV2C({ ...values, manufacturerId, deviceType, deviceSerialNumber })}
        updateV2CStatus={props.updateV2CStatus}
        showSuccessToast={props.showSuccessToast}
        showErrorToast={props.showErrorToast}
      />
    </>
	);
};

V2CTab.propTypes = {
  updateV2C: PropTypes.func.isRequired,
  updateV2CStatus: PropTypes.string.isRequired,
  showSuccessToast: PropTypes.func.isRequired,
  showErrorToast: PropTypes.func.isRequired,
};

export default V2CTab;