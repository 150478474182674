import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import { parseFetchAllRequest, prepareLicenseRequest } from './license.parsers';
import { NOOP } from 'containers/App/app.constants';
import { createSelector } from '@reduxjs/toolkit';
import { ENTITIES_STATE_KEY, ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import _ from 'lodash';

export const STATE_KEY = ENTITY_TYPES.LICENSES;

const extraAsyncActions = [
	{
		type: 'regenerateLicense',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: NOOP
	},
	{
		type: 'validateLicense',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: NOOP
	},
	{
		type: 'disableLicense',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: NOOP
	},
	{
		type: 'enableLicense',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: NOOP
	},
	{
		type: 'viewLicense',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: (state, action) => {
			state.decryptedLicense = action.payload.data.decryptedLicense;
			return state;
		}
	},
	{
		type: 'abortLicenseActions',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: NOOP
	},
  {
		type: 'downloadLicense',
		requestPrepareFn: prepareLicenseRequest,
		setStateDataFn: NOOP
	}
]

const mergeCustomizerFn = (objValue, srcValue) => {
	if (_.isArray(objValue)) {
		return srcValue;
	}
}

const licenseEntitySlice = createNewEntitySlice({
	name: STATE_KEY,
	parseFetchAllRequest,
	extraAsyncActions,
	mergeCustomizerFn
});

const { reducer, actions, selectors } = licenseEntitySlice;
const {
	fetchAll: fetchLicenses,
	fetchAllPending: fetchLicensesPending,
	fetchAllSuccess: fetchLicensesSuccess,
	fetchAllFailure: fetchLicensesFailure,

	fetchById: fetchLicense,
	fetchByIdPending: fetchLicensePending,
	fetchByIdSuccess: fetchLicenseSuccess,
	fetchByIdFailure: fetchLicenseFailure,

	create: createLicense,
	createPending: createLicensePending,
	createSuccess: createLicenseSuccess,
	createFailure: createLicenseFailure,

	update: updateLicense,
	updatePending: updateLicensePending,
	updateSuccess: updateLicenseSuccess,
	updateFailure: updateLicenseFailure,

	regenerateLicense,
	regenerateLicensePending,
	regenerateLicenseSuccess,
	regenerateLicenseFailure,

	validateLicense,
	validateLicensePending,
	validateLicenseSuccess,
	validateLicenseFailure,

	disableLicense,
	disableLicensePending,
	disableLicenseSuccess,
	disableLicenseFailure,

	enableLicense,
	enableLicensePending,
	enableLicenseSuccess,
	enableLicenseFailure,

	viewLicense,
	viewLicensePending,
	viewLicenseSuccess,
	viewLicenseFailure,

	abortLicenseActions,
	abortLicenseActionsPending,
	abortLicenseActionsSuccess,
	abortLicenseActionsFailure,

  downloadLicense,
  downloadLicensePending,
	downloadLicenseSuccess,
	downloadLicenseFailure,
} = actions;

export const licenseEntityActions = {
	fetchLicenses,
	fetchLicensesPending,
	fetchLicensesSuccess,
	fetchLicensesFailure,

	fetchLicense,
	fetchLicensePending,
	fetchLicenseSuccess,
	fetchLicenseFailure,

	createLicense,
	createLicensePending,
	createLicenseSuccess,
	createLicenseFailure,

	updateLicense,
	updateLicensePending,
	updateLicenseSuccess,
	updateLicenseFailure,

	regenerateLicense,
	regenerateLicensePending,
	regenerateLicenseSuccess,
	regenerateLicenseFailure,

	validateLicense,
	validateLicensePending,
	validateLicenseSuccess,
	validateLicenseFailure,

	disableLicense,
	disableLicensePending,
	disableLicenseSuccess,
	disableLicenseFailure,

	enableLicense,
	enableLicensePending,
	enableLicenseSuccess,
	enableLicenseFailure,

	viewLicense,
	viewLicensePending,
	viewLicenseSuccess,
	viewLicenseFailure,

	abortLicenseActions,
	abortLicenseActionsPending,
	abortLicenseActionsSuccess,
	abortLicenseActionsFailure,

  downloadLicense,
  downloadLicensePending,
	downloadLicenseSuccess,
	downloadLicenseFailure,
};

const getEntitiesState = state => state[ENTITIES_STATE_KEY] || {};

const getState = createSelector(
	getEntitiesState,
	state => _.get(state, STATE_KEY)
);

const {
	selectAll: selectLicenses,
	selectFetchAllPending: selectLicensesPending,
	createSelectByIdOrIds: createSelectLicensesByIdOrIds,
	selectCreatePending: selectCreateLicensePending,
	selectFetchByIdPending: selectFetchLicensePending,
} = selectors;


export const licenseEntitySelectors = {
	selectLicenses,
	selectLicensesPending,
	createSelectLicensesByIdOrIds,
	selectCreateLicensePending,
	selectFetchLicensePending,
};

export default reducer;