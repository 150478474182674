import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { alarmsEntityActions } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import { createResetGridDataAction, createResetGridReducer, createSetGridParamsAction, createSetGridParamsReducer, createUpdateGridReducer, GRID_INITIAL_STATE } from 'containers/Grid/grid.utils';


const STATE_KEY = 'alarms';
const GRID_STATE_KEY = 'alarmsGrid';
const setGridParams = createSetGridParamsAction(GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(GRID_STATE_KEY);

export const INITIAL_STATE = {
	[GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const slice = createSlice({
	name:          STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
	},
	extraReducers: {
		//GRID
		[alarmsEntityActions.fetchAlarmsSuccess]: createUpdateGridReducer(GRID_STATE_KEY),
		[setGridParams]:                  createSetGridParamsReducer(GRID_STATE_KEY),
		[resetGridData]:                  createResetGridReducer(GRID_STATE_KEY),
	}
});

const getState = state => state[STATE_KEY] || INITIAL_STATE;

const selectAlarmsGrid = createSelector(
	getState,
	alarmsState => {
		return alarmsState[GRID_STATE_KEY]
	}
);

const selectAlarmIdsForPage = (state, props) => {
	const gridState = selectAlarmsGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const alarmsSelectors = {
	selectAlarmsGrid,
	selectAlarmIdsForPage,
};

export const alarmsActions = {
	setGridParams,
	resetGridData
};
export default slice.reducer;