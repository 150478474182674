import _ from 'lodash';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { EMPTY_OBJECT } from 'containers/App/app.constants';
import { NOOP } from 'containers/App/app.constants';
import { API_ERROR_CODES, DEFAULT_DATA_PROP, DEFAULT_ERROR_PROP, DEFAULT_LOADING_PROP, ENTITIES_STATE_KEY, SET_ENTITIES_ACTION_TYPE, ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createEntitiesByIdOrIdsSelector, createEntitiesSelector, createEntityIsLoadingSelector,createEntityErrorSelector } from 'containers/App/modules/entities/entities.selectors';
import { put } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'containers/App/modules/toasts/toasts.actions';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { createActionWithScope, createAsyncReducer, getFormattedScopeName } from 'containers/App/modules/redux.util';
import {intl} from 'containers/IntlGlobalProvider';

import messages from './entities.messages';

const FETCH_ALL_ACTION_TYPE = 'fetchAll';
const FETCH_BY_ID_ACTION_TYPE = 'fetchById';
const CREATE_ACTION_TYPE = 'create';
const UPDATE_ACTION_TYPE = 'update';
const DELETE_ACTION_TYPE = 'delete';
const PENDING_PROP = 'Loading';
const ERROR_PROP = 'Error';
const FETCH_ALL_PENDING_PROP = FETCH_ALL_ACTION_TYPE + PENDING_PROP;
const FETCH_ALL_ERROR_PROP = FETCH_ALL_ACTION_TYPE + ERROR_PROP;
const FETCH_BY_ID_PENDING_PROP = FETCH_BY_ID_ACTION_TYPE + PENDING_PROP;
const FETCH_BY_ID_ERROR_PROP = FETCH_BY_ID_ACTION_TYPE + ERROR_PROP;
const CREATE_PENDING_PROP = CREATE_ACTION_TYPE + PENDING_PROP;
const CREATE_ERROR_PROP = CREATE_ACTION_TYPE + ERROR_PROP;
const UPDATE_PENDING_PROP = UPDATE_ACTION_TYPE + PENDING_PROP;
const UPDATE_ERROR_PROP = UPDATE_ACTION_TYPE + ERROR_PROP;
const DELETE_PENDING_PROP = DELETE_ACTION_TYPE + PENDING_PROP;
const DELETE_ERROR_PROP = DELETE_ACTION_TYPE + ERROR_PROP;
const entityTypesToEntityText = () => ({
	[ENTITY_TYPES.USERS]: 				intl?.formatMessage(messages.user),
	[ENTITY_TYPES.DEVICES]: 				intl?.formatMessage(messages.device),
	[ENTITY_TYPES.SW_VERSIONS]: 	intl?.formatMessage(messages.softwareVersion),
	[ENTITY_TYPES.LUMENISX_VERSIONS]: 	intl?.formatMessage(messages.lumenisxVersion),
	[ENTITY_TYPES.GROUPS]: 				intl?.formatMessage(messages.group),
	[ENTITY_TYPES.V2C]: 				intl?.formatMessage(messages.v2c),
	[ENTITY_TYPES.ALARMS]: 				intl?.formatMessage(messages.alarms),
	[ENTITY_TYPES.DEVICE_ALARMS]:		intl?.formatMessage(messages.deviceAlarms),

})

export const setEntities = createAction('[entities]/setEntities');

export const createPrepareFn = (parseResponseFn) => (payload) => {
	// const meta = _.get(payload, 'data.metadata'); //todo think about createPrepareFn. I have no control over meta (or error) here, only on payload of returned action
	const data = parseResponseFn(payload);
	return {
		payload: data,
		// meta
	};
};

const defaultParse = payload => payload;
export const createNewEntitySlice = ({
	                                     name:          entityType,
	                                     extraReducers: userExtraReducers = {},
	                                     extraAsyncActions = [],
	                                     extraCaseReducers = {},
	                                     mergeCustomizerFn, //lodash mergeWith() customizer function

	                                     parseFetchAllRequest = defaultParse,
	                                     parseFetchAllSuccess = defaultParse,
	                                     parseFetchAllFailure = defaultParse,

	                                     parseFetchByIdRequest = defaultParse,
	                                     parseFetchByIdSuccess = defaultParse,
	                                     parseFetchByIdFailure = defaultParse,

	                                     parseCreateRequest = defaultParse,
	                                     parseCreateSuccess = defaultParse,
	                                     parseCreateFailure = defaultParse,

	                                     parseUpdateRequest = defaultParse,
	                                     parseUpdateSuccess = defaultParse,
	                                     parseUpdateFailure = defaultParse,

	                                     parseDeleteRequest = defaultParse,
	                                     parseDeleteSuccess = defaultParse,
	                                     parseDeleteFailure = defaultParse,

                                     }) => {

	const entityActions = [
		{
			type:             FETCH_ALL_ACTION_TYPE,
			requestPrepareFn: createPrepareFn(parseFetchAllRequest),
			successPrepareFn: createPrepareFn(parseFetchAllSuccess),
			failurePrepareFn: createPrepareFn(parseFetchAllFailure),
			setStateDataFn:   NOOP
		},
		{
			type:             FETCH_BY_ID_ACTION_TYPE,
			requestPrepareFn: createPrepareFn(parseFetchByIdRequest),
			successPrepareFn: createPrepareFn(parseFetchByIdSuccess),
			failurePrepareFn: createPrepareFn(parseFetchByIdFailure),
			setStateDataFn:   NOOP
		},
		{
			type:             CREATE_ACTION_TYPE,
			requestPrepareFn: createPrepareFn(parseCreateRequest),
			successPrepareFn: createPrepareFn(parseCreateSuccess),
			failurePrepareFn: createPrepareFn(parseCreateFailure),
			setStateDataFn:   NOOP
		},
		{
			type:             UPDATE_ACTION_TYPE,
			requestPrepareFn: createPrepareFn(parseUpdateRequest),
			successPrepareFn: createPrepareFn(parseUpdateSuccess),
			failurePrepareFn: createPrepareFn(parseUpdateFailure),
			setStateDataFn:   NOOP
		},
		{
			type:             DELETE_ACTION_TYPE,
			requestPrepareFn: createPrepareFn(parseDeleteRequest),
			successPrepareFn: createPrepareFn(parseDeleteSuccess),
			failurePrepareFn: createPrepareFn(parseDeleteFailure),
			setStateDataFn:   NOOP
		},
		...extraAsyncActions
	];

	const caseReducers = entityActions.map(actionConfig => createAsyncReducer(actionConfig));
	const reducers = _.reduce(caseReducers, (acc, reducer) => ({ ...acc, ...reducer }), {});

	const entitySlice = createSlice({
		name:          getFormattedScopeName(entityType),
		initialState:  {},
		reducers:      {
			...reducers,
			...extraCaseReducers,
		},
		extraReducers: {
			[setEntities]: (state, action) => {
				const entitiesByType = _.get(action, `payload.entities.${entityType}`);
				state.byId = _.mergeWith({}, state.byId, entitiesByType, mergeCustomizerFn);
				// state.byId = _.mergeWith({}, state.byId, entitiesByType);
			},
			...userExtraReducers,
		}
	});


	const selectors = {
		selectAll:              createEntitiesSelector(entityType),
		selectFetchAllPending:  createEntityIsLoadingSelector(entityType, FETCH_ALL_PENDING_PROP),
		createSelectByIdOrIds:  createEntitiesByIdOrIdsSelector(entityType),
		selectCreatePending:    createEntityIsLoadingSelector(entityType, CREATE_PENDING_PROP),
		selectFetchByIdPending: createEntityIsLoadingSelector(entityType, FETCH_BY_ID_PENDING_PROP),
		selectCreateError:    createEntityErrorSelector(entityType, CREATE_ERROR_PROP),
	};

	return {
		...entitySlice, selectors
	};
};


//SAGA
export function* successToast(message = 'Success', description) {
	yield put(
		showSuccessToast({
				title: message,
				description
			}
		)
	);
}

export function* errorToast(action, message = 'Failure', moreInfo = null) {
	const errorCode = _.get(action, 'payload.errorCode');
	const code = API_ERROR_CODES[errorCode] || errorCode;
	const description = moreInfo ? code + ' : ' + moreInfo : code;

	yield put(
		showErrorToast({
			title: message,
			description
		}));
}

export function* createEntitySuccess(entityType, action) {
	const entityText = entityTypesToEntityText()[entityType];
	const createMsg = intl.formatMessage(messages.createEntitySuccess, {entityType: entityText});

	yield put(modalActions.hideModal());
	yield successToast(createMsg);
}

export function* createEntityFailure(action) {
	yield put(modalActions.hideModal());
	yield errorToast(action, 'Creation Failure');
}

export function* updateEntitySuccess(entityType, action) {
	const entityText = entityTypesToEntityText()[entityType];
	const updateMsg = intl.formatMessage(messages.updateEntitySuccess, {entityType: entityText});

	yield put(modalActions.hideModal());
	yield successToast(updateMsg);
}

export function* updateEntityFailure(action) {
	yield put(modalActions.hideModal());
	yield errorToast(action, 'Update Failure');
}