import { ENTITY_TYPES } from "containers/App/modules/entities/entities.constants";
import { createNewEntitySlice } from "containers/App/modules/entities/entities.utils";
import _ from "lodash";

import {
  parseFetchAllSuccess,
  parseCreateRequest,
  parseCreateSuccess,
  parseFetchAllRequest,
  prepareUpdateDevicePropertiesRequest,
  prepareUpdateAssignedUsersRequest,
  prepareGenerateLogsRequest,
  prepareUpdateDeviceLogsModeRequest,
  prepareDeviceCommandsRequest,
} from "./devices.parsers";
import { NOOP } from "containers/App/app.constants";

const extraAsyncActions = [
  {
    type: "fetchAssignedUsers",
    setStateDataFn: NOOP,
  },
  {
    type: "updateAssignedUsers",
    requestPrepareFn: prepareUpdateAssignedUsersRequest,
    setStateDataFn: NOOP,
  },
  {
    type: "fetchCustomerDetails",
    setStateDataFn: NOOP,
  },
  {
    type: "updateDeviceProperties",
    requestPrepareFn: prepareUpdateDevicePropertiesRequest,
    setStateDataFn: NOOP,
  },
  {
    type: "fetchDeviceProperties",
    setStateDataFn: NOOP,
  },
  {
    type: "deactivateDevice",
    setStateDataFn: NOOP,
  },
  {
    type: "resetDevice",
    setStateDataFn: NOOP,
  },
  {
    type: "reactivateDevice",
    setStateDataFn: NOOP,
  },
  {
    type: "changeLogMode",
    requestPrepareFn: prepareUpdateDeviceLogsModeRequest,
    setStateDataFn: NOOP,
  },
  {
    type: "generateLogs",
    setStateDataFn: NOOP
  },
  {
    type: "installSecurityPatch",
    setStateDataFn: NOOP
  },
  {
    type: "fetchDeviceCommands",
    requestPrepareFn: prepareDeviceCommandsRequest,
    setStateDataFn: NOOP,
  },
  {
    type: "startCommand",
    setStateDataFn: NOOP,
  },
  {
    type: "updateCommand",
    setStateDataFn: NOOP,
  },
  {
    type: "searchCommands",
    setStateDataFn: NOOP,
  },
  {
    type: "fetchDeviceFiles",
    setStateDataFn: NOOP,
  },
];

const deviceEntitySlice = createNewEntitySlice({
  name: ENTITY_TYPES.DEVICES,
  extraAsyncActions,
  // parseFetchAllSuccess,
  parseCreateRequest,
  parseCreateSuccess,
  parseFetchAllRequest,
});

const { reducer, actions, selectors } = deviceEntitySlice;
const {
  fetchAll: fetchDevices,
  fetchAllPending: fetchDevicesPending,
  fetchAllSuccess: fetchDevicesSuccess,
  fetchAllFailure: fetchDevicesFailure,

  create: createDevice,
  createPending: createDevicePending,
  createSuccess: createDeviceSuccess,
  createFailure: createDeviceFailure,

  fetchAssignedUsers,
  fetchAssignedUsersFailure,
  fetchAssignedUsersPending,
  fetchAssignedUsersSuccess,

  fetchCustomerDetails,
  fetchCustomerDetailsFailure,
  fetchCustomerDetailsPending,
  fetchCustomerDetailsSuccess,

  updateAssignedUsers,
  updateAssignedUsersFailure,
  updateAssignedUsersPending,
  updateAssignedUsersSuccess,

  updateDeviceProperties,
  updateDevicePropertiesFailure,
  updateDevicePropertiesPending,
  updateDevicePropertiesSuccess,

  fetchDeviceProperties,
  fetchDevicePropertiesPending,
  fetchDevicePropertiesSuccess,
  fetchDevicePropertiesFailure,

  deactivateDevice,
  deactivateDevicePending,
  deactivateDeviceSuccess,
  deactivateDeviceFailure,

  resetDevice,
  resetDevicePending,
  resetDeviceSuccess,
  resetDeviceFailure,

  reactivateDevice,
  reactivateDevicePending,
  reactivateDeviceSuccess,
  reactivateDeviceFailure,

  changeLogMode,
  changeLogModePending,
  changeLogModeSuccess,
  changeLogModeFailure,

  generateLogs,
  generateLogsPending,
  generateLogsSuccess,
  generateLogsFailure,

  installSecurityPatch,
  installSecurityPatchPending,
  installSecurityPatchSuccess,
  installSecurityPatchFailure,

  fetchDeviceCommands,
  fetchDeviceCommandsPending,
  fetchDeviceCommandsSuccess,
  fetchDeviceCommandsFailure,

  startCommand,
  startCommandPending,
  startCommandSuccess,
  startCommandFailure,

  updateCommand,
  updateCommandPending,
  updateCommandSuccess,
  updateCommandFailure,

  searchCommands,
  searchCommandsPending,
  searchCommandsSuccess,
  searchCommandsFailure,

  fetchDeviceFiles,
  fetchDeviceFilesPending,
  fetchDeviceFilesSuccess,
  fetchDeviceFilesFailure,
} = actions;

export const deviceEntityActions = {
  fetchDevices,
  fetchDevicesPending,
  fetchDevicesSuccess,
  fetchDevicesFailure,

  fetchDeviceProperties,
  fetchDevicePropertiesPending,
  fetchDevicePropertiesSuccess,
  fetchDevicePropertiesFailure,

  createDevice,
  createDevicePending,
  createDeviceSuccess,
  createDeviceFailure,

  fetchAssignedUsers,
  fetchAssignedUsersFailure,
  fetchAssignedUsersPending,
  fetchAssignedUsersSuccess,

  fetchCustomerDetails,
  fetchCustomerDetailsFailure,
  fetchCustomerDetailsPending,
  fetchCustomerDetailsSuccess,

  updateAssignedUsers,
  updateAssignedUsersFailure,
  updateAssignedUsersPending,
  updateAssignedUsersSuccess,

  updateDeviceProperties,
  updateDevicePropertiesFailure,
  updateDevicePropertiesPending,
  updateDevicePropertiesSuccess,

  deactivateDevice,
  deactivateDevicePending,
  deactivateDeviceSuccess,
  deactivateDeviceFailure,

  resetDevice,
  resetDevicePending,
  resetDeviceSuccess,
  resetDeviceFailure,

  reactivateDevice,
  reactivateDevicePending,
  reactivateDeviceSuccess,
  reactivateDeviceFailure,

  changeLogMode,
  changeLogModePending,
  changeLogModeSuccess,
  changeLogModeFailure,

  fetchDeviceCommands,
  fetchDeviceCommandsPending,
  fetchDeviceCommandsSuccess,
  fetchDeviceCommandsFailure,

  startCommand,
  startCommandPending,
  startCommandSuccess,
  startCommandFailure,

  generateLogs,
  generateLogsPending,
  generateLogsSuccess,
  generateLogsFailure,

  installSecurityPatch,
  installSecurityPatchPending,
  installSecurityPatchSuccess,
  installSecurityPatchFailure,

  updateCommand,
  updateCommandPending,
  updateCommandSuccess,
  updateCommandFailure,

  searchCommands,
  searchCommandsPending,
  searchCommandsSuccess,
  searchCommandsFailure,

  fetchDeviceFiles,
  fetchDeviceFilesPending,
  fetchDeviceFilesSuccess,
  fetchDeviceFilesFailure,
};

const {
  selectAll: selectDevices,
  selectFetchAllPending: selectDevicesPending,
  createSelectByIdOrIds: createSelectDevicesByIdOrIds,
  selectCreatePending: selectCreateDevicePending,
} = selectors;

const selectFetchAssignedUsersPending = (state) => {
  return _.get(state, "entities.devices.fetchAssignedUsersLoading");
};
const selectUpdateAssignedUsersPending = (state) =>
  _.get(state, "entities.devices.updateAssignedUsersLoading");
const selectFetchCustomerDetailsPending = (state) =>
  _.get(state, "entities.devices.fetchCustomerDetailsLoading");
const selectUpdateDevicePropertiesPending = (state) =>
  _.get(state, "entities.devices.updateDevicePropertiesLoading");
const selectDeactivateDevicePending = (state) =>
  _.get(state, "entities.devices.deactivateDeviceLoading");
const selectReactivateDevicePending = (state) =>
  _.get(state, "entities.devices.reactivateDeviceLoading");
const selectResetDevicePending = (state) =>
  _.get(state, "entities.devices.resetDeviceLoading");
const selectFetchDeviceCommandsPending = (state) =>
  _.get(state, "entities.devices.fetchDeviceCommandsLoading");
const selectStartDeviceCommandPending = (state) =>
  _.get(state, "entities.devices.startDeviceCommandsLoading");
const selectUpdateDeviceCommandPending = (state) =>
  _.get(state, "entities.devices.updateDeviceCommandsLoading");
const selectSearchCommandsPending = (state) =>
  _.get(state, "entities.devices.searchCommandsLoading");
const selectFetchDeviceFilesPending = (state) =>
  _.get(state, "entities.devices.fetchDeviceFilesLoading");

export const deviceEntitySelectors = {
  selectDevices,
  selectDevicesPending,
  createSelectDevicesByIdOrIds,
  selectCreateDevicePending,

  selectFetchAssignedUsersPending,
  selectUpdateAssignedUsersPending,
  selectFetchCustomerDetailsPending,
  selectUpdateDevicePropertiesPending,
  selectDeactivateDevicePending,
  selectReactivateDevicePending,
  selectResetDevicePending,
  selectFetchDeviceCommandsPending,
  selectStartDeviceCommandPending,
  selectUpdateDeviceCommandPending,
  selectSearchCommandsPending,
  selectFetchDeviceFilesPending,
};

export default reducer;
