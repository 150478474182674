import React from "react";
import PropTypes from "prop-types";
import FormFactory from "components/FormFactory";
import { Col, Row } from "antd";
import { withErrorBoundary } from "components/ErrorBoundary";
import TextAreaField from "components/FormFactory/fields/TextAreaField";
import TextField from "components/FormFactory/fields/TextField";
import FileBrowserField from "components/FormFactory/fields/FileBrowserField";
import commonMessages from "src/commonMessages";
import messages from "../messages";

const errorsMap={
    'APPLICATION_VERSION_ALREADY_EXISTS':messages.versionAlreadyExist
};

const validateMessages = {
	required: '%s is required',
	array: {
	  max: '%s cannot contain more than %s file'
	}
};

const Form = FormFactory.create({
	name: "creatLumenisXForm",
	onFieldsChange: (props, changedFields, allFields) => {
	},
	validateMessages,
});

class LumenisXForm extends React.Component {
	static propTypes = {
		intl: PropTypes.object.isRequired,
		isCreatePending: PropTypes.bool,
		errorCode:PropTypes.any
	};

	onSubmit = (lumenisXVersionValues) => {
		this.props.createLumenisXVersion(lumenisXVersionValues);
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	render() {
		const { isCreatePending, intl, errorCode } = this.props;
		const submitButtonText = intl.formatMessage(commonMessages.save);
		const uploadText = intl.formatMessage(messages.upload);
		const versionText = intl.formatMessage(messages.version);
		const commentsText = intl.formatMessage(messages.comments);
		return (
			<Form
				submitButtonText={submitButtonText}
				hideSubmitButton={false}
				onSubmit={this.onSubmit}
				isLoading={isCreatePending}
				hideCancelButton={false}
				onCancel={this.onCancel}
			>
				{(form) => (
					<Row gutter={24}>
						<Col span={9}>
							<FileBrowserField
								name="upload"
								label={uploadText}
								required
								disabled={false}
							/>
							<TextField
								name="version"
								label={versionText}
								required
								disabled={false}
								maxLength={36}
								errorMessage={errorsMap[errorCode] && intl.formatMessage(errorsMap[errorCode])}
							/>
							<TextAreaField
								name="comments"
								label={commentsText}
								autoSize={{ minRows: 6, maxRows: 6 }}
								maxLength={256}
								disabled={false}
							/>
						</Col>
					</Row>
				)}
			</Form>
		);
	}
}

export default withErrorBoundary(LumenisXForm);
