import Axios from 'axios';

/**
|--------------------------------------------------
| API Service to make API calls.
|   The main use is for the token handler - the api 
|   service will add the token for each request
|   and will refresh the token if needed
|--------------------------------------------------
*/

const TIME_BEFORE_CAN_REFRESH_AGAIN = 60 * 1000;
const DEFAULT_TIMEOUT = 10000;

const REFRESH_STATUS = {
  REFRESHING: 'REFRESHING',
  NONE: 'NONE',
};

class ApiService {
  static shouldRefreshToken = true;

  /**
   * Creates an instance of ApiService.
   * @param {any} { baseURL, refreshTokenResource, tokenHandler }
   *      baseURL - the base url of the server.
   *      refreshTokenResource - full resource object that includes the baseUrl.
   *      tokenHandler - an object that has 4 methods getToken,setToken,getRefreshToken,setRefreshToken
   */
  constructor({ baseURL = '', refreshTokenResource = {}, tokenHandler = {}, timeout = DEFAULT_TIMEOUT }) {
    this._config = {
      baseURL,
      refreshTokenResource,
      tokenHandler,
      timeout,
    };

    this._configInternal();
    this._refreshCallBacks = {};
    this._refreshingStatus = REFRESH_STATUS.NONE;
  }

  set Config(newConfig) {
    this._config = {
      ...this._config,
      ...newConfig,
    };

    this._updateAxiosClient();
  }

  get Config() {
    return this._config;
  }

  _updateAxiosClient() {
    this._axiosClient.defaults.baseURL = this._config.baseURL;
    this._axiosClient.defaults.timeout = this._config.timeout;
  }

  _createAxiosClient() {
    this._axiosClient = Axios.create({
      baseURL: this._config.baseURL,
      timeout: this._config.timeout,
    });
  }

  _configInternal() {
    this._createAxiosClient();
    this._axiosClient.interceptors.request.use(this._successRequestInterceptor, null);

    this._axiosClient.interceptors.response.use(null, this._errorResponseInterceptor);
  }

  _successRequestInterceptor = async request => {
    if (!request.headers.Authorization && request.requireAuth) {
      const token = `Bearer ${await this._config.tokenHandler.getToken()}`;

      return {
        ...request,
        headers: { ...request.headers, Authorization: token },
      };
    }

    return request;
  };

  _shouldRefreshToken = error =>
    error && error.response && error.response.status === 401 && error.response.data && error.response.data.code === 'TOKEN_NOT_VALID';

  _waitForRefresh = () => {
    let id = guid();

    return new Promise((resolve, reject) => {
      this._refreshCallBacks[id] = (err, token) => {
        delete this._refreshCallBacks[id];
        if (err) {
          reject(err);
          return;
        }
        resolve(token);
      };
    });
  };

  _invokeCallBacks = (err, token) => {
    Object.values(this._refreshCallBacks).forEach(f => f(err, token));
  };

  _errorResponseInterceptor = async response => {
    const shouldRefreshToken = this._shouldRefreshToken(response);

    if (shouldRefreshToken) {
      let newToken;
      if (this._refreshingStatus !== REFRESH_STATUS.REFRESHING) {
        try {
          const request = ApiService.createRequestObject({
            resource: this._config.refreshTokenResource,
            data: {
              refreshToken: await this._config.tokenHandler.getRefreshToken(),
            },
          });

          this._refreshingStatus = REFRESH_STATUS.REFRESHING;
          const result = await Axios(request);
          this._config.tokenHandler.setToken(result.data.token);
          newToken = result.data.token;
          this._invokeCallBacks(null, newToken);
        } catch (e) {
          this._config.tokenHandler.onTokenFailed();
          this._invokeCallBacks(e, null);
          return Promise.reject(response);
        } finally {
          this._refreshingStatus = REFRESH_STATUS.NONE;
        }
      } else {
        try {
          newToken = await this._waitForRefresh();
        } catch (e) {
          return Promise.reject(response);
        }
      }

      response.config.headers['Authorization'] = `Bearer ${newToken}`;

      return this._axiosClient(response.config);
    }

    return Promise.reject(response);
  };

  async sendRequest({ resource, data, params, additionalPath }) {
    const response = await this._axiosClient(
      ApiService.createRequestObject({
        resource,
        data,
        params,
        additionalPath,
      }),
    );

    return response;
  }

  /**
   * Convert our parameters to Axios config format, more can be added here if needed,
   * @see Axios web page for more information about other configuration
   * https://github.com/mzabriskie/axios
   */
  static createRequestObject({ resource, data, params, additionalPath, additionalOptions }) {
    let actualUri = resource.uri;

    if (additionalPath !== undefined) {
      actualUri = resource.uri + '/' + additionalPath;
    }

    return {
      withCredentials: true,
      ...resource,
      requireAuth: resource.requireAuth || false,
      headers: resource.headers || {},
      data,
      params,
      url: actualUri,
      ...additionalOptions,
    };
  }
}

/**
 * Creates a new apiService instance
 *
 * @param {any} config -
 *      baseURL - the base url of the server.
 *      refreshTokenResource - full resource object that includes the baseUrl.
 *      tokenHandler - an object that has 4 methods getToken,setToken,getRefreshToken,setRefreshToken
 */
function createInstance(config) {
  let newInstance = new ApiService(config);

  return newInstance;
}

//Create the singleton instance
const instance = new ApiService({});

//Assign the create only to him
instance.create = createInstance;

export default instance;

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
