import { call, takeLatest, put } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { securityVersionEntityActions } from "containers/App/modules/entities/securityVersions/securityVersionEntity.slice";
import securityVersionMessages from "pages/SecurityVersions/messages";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { successToast, errorToast } from "containers/App/modules/entities/entities.utils";
import { parseFetchAllSuccess } from "containers/App/modules/entities/securityVersions/securityVersions.parsers";
import { securityVersionsActions } from '../../../../../pages/SecurityVersions/modules/securityVersions.slice';
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import { MODAL_TYPES } from "containers/ModalRoot/components/constants";
import { calculateFileChecksum } from "../../app.saga";
import JSZip from 'jszip';


function* fetchSecurityVersions(action) {
	yield call(api, {
		apiFn: BackendService.fetchSecurityVersions,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.securityVersions],
		params: action.payload,
		actions: [
			securityVersionEntityActions.fetchSecurityVersionsPending,
			securityVersionEntityActions.fetchSecurityVersionsSuccess,
			securityVersionEntityActions.fetchSecurityVersionsFailure,
		],
	});
}

function* updateSecurityVersion(action) {
	const data = action.payload;
	const versionId = data.versionId
	delete data.versionId;

	yield call(api, {
		apiFn: BackendService.patchSecurityVersion,
		params: { data, versionId },
		actions: [
			securityVersionEntityActions.updateSecurityVersionPending,
			securityVersionEntityActions.updateSecurityVersionSuccess,
			securityVersionEntityActions.updateSecurityVersionFailure
		]
	});
	yield call(data.refresh);
}

function* updateSecurityVersionSuccess() {
	yield call(successToast, securityVersionMessages.updateSecurityVersionSuccess.defaultMessage);
}

function* updateSecurityVersionFailure(action) {
	yield call(
		errorToast,
		action,
		securityVersionMessages.updateSecurityVersionError.defaultMessage,
		action.payload.description
	);
}

function* deleteSecurityVersion(action) {
	const data = action.payload;

	yield call(api, {
		apiFn: BackendService.deleteSecurityVersion,
		params: { versionId: data.versionId },
		actions: [
			securityVersionEntityActions.deleteSecurityVersionPending,
			securityVersionEntityActions.deleteSecurityVersionSuccess,
			securityVersionEntityActions.deleteSecurityVersionFailure
		]
	});
	yield call(data.refresh);
}

function* deleteSecurityVersionSuccess() {
	yield call(successToast, securityVersionMessages.deleteSecurityVersionSuccess.defaultMessage);
}

function* deleteSecurityVersionFailure(action) {
	yield call(
		errorToast,
		action,
		securityVersionMessages.deleteSecurityVersionError.defaultMessage,
		action.payload.description
	);
}

function* onGetSecurityVersionUploadUrl(action) {
  const params = action.payload;
	const file = params.upload[0];
	const version = file.name.replace(".exe", "");

  yield call(api, {
    apiFn: BackendService.uploadSecurityVersion, 
    params: { version },
    actions: [
      securityVersionEntityActions.uploadSecurityVersionPending,
      (response) => securityVersionEntityActions.getSecurityVersionUploadUrlSuccess({
        ...response,
        file, 
        version 
      }),      
      securityVersionEntityActions.uploadSecurityVersionFailure,
      ]
    }
  );
}


function* onUploadSecurityVersion(action) {
  const url = action.payload.data.url;
  const file = action.payload.file;
  const version = action.payload.version;

  const fileToUpload = new File([file.originFileObj], version, {
    type: file.originFileObj.type
  });

  yield call(BackendService.uploadFile, url, fileToUpload);
  
  // Read the zip
  const zip = new JSZip();
  const zipContent = yield call([zip, zip.loadAsync], fileToUpload);
  
  // Get the files from zip
  const metadataFile = yield call([zipContent.files['metadata.json'], 'async'], 'blob');
  const validatorFile = yield call([zipContent.files['validator.exe'], 'async'], 'blob');
  const dataZipFile = yield call([zipContent.files['data_zip.exe'], 'async'], 'blob');

  // Calculate checksums using existing method
  const metadataChecksum = yield call(calculateFileChecksum, metadataFile);
  const validatorChecksum = yield call(calculateFileChecksum, validatorFile);
  const versionChecksum = yield call(calculateFileChecksum, dataZipFile);

  const checksums = {
    metadataChecksum,
    validatorChecksum,
    versionChecksum
  };

  yield call(api, {
    apiFn: BackendService.getSecurityVersionMetadataPreview,
    params: { version, checksums },
    actions: [
      securityVersionEntityActions.uploadSecurityVersionPending,
      securityVersionEntityActions.uploadSecurityVersionSuccess,
      securityVersionEntityActions.uploadSecurityVersionFailure,
    ],
  });
}

function* uploadSecurityVersionFailure(action) {
	yield call(
		errorToast,
		action,
		securityVersionMessages.getSecurityVersionMetadataPreviewError.defaultMessage,
		action.payload.description
	);
}

function* uploadSecurityVersionSuccess(action) {
	yield put(securityVersionsActions.setSecurityVersionMetadataPreview({...action.payload.data, checksums: action.payload.requestParams.checksums}));
	yield put(modalActions.hideModal());
	yield put(modalActions.showModal(
		{
			modalType: MODAL_TYPES.SECURITY_VERSION_METADATA_PREVIEW_MODAL,
			modalProps: {
				title: "Security Version Metadata Preview",
				footer: null,
				width: 700,
			},
			componentProps: {},
		}
	));
}

function* createSecurityVersion(action) {
	const data = action.payload.data;
	const refresh = action.payload.refresh;
	yield call(api, {
		apiFn: BackendService.createSecurityVersion,
		params: { data },
		actions: [
			securityVersionEntityActions.createSecurityVersionPending,
			securityVersionEntityActions.createSecurityVersionSuccess,
			securityVersionEntityActions.createSecurityVersionFailure
		]
	});
	yield call(refresh);
}

function* createSecurityVersionSuccess() {
	yield put(modalActions.hideModal());
	yield put(securityVersionsActions.setSecurityVersionMetadataPreview(null));
	yield call(successToast, securityVersionMessages.createSecurityVersionSuccess.defaultMessage);
}

function* createSecurityVersionFailure(action) {
	yield call(
		errorToast,
		action,
		securityVersionMessages.createSecurityVersionError.defaultMessage,
		action.payload.description
	);
}

export default function* watchSecurityVersionsSaga() {
	yield takeLatest(securityVersionEntityActions.fetchSecurityVersions, fetchSecurityVersions);
	yield takeLatest(securityVersionEntityActions.updateSecurityVersion, updateSecurityVersion);
	yield takeLatest(securityVersionEntityActions.updateSecurityVersionSuccess, updateSecurityVersionSuccess);
	yield takeLatest(securityVersionEntityActions.updateSecurityVersionFailure, updateSecurityVersionFailure);
	yield takeLatest(securityVersionEntityActions.deleteSecurityVersion, deleteSecurityVersion);
	yield takeLatest(securityVersionEntityActions.deleteSecurityVersionSuccess, deleteSecurityVersionSuccess);
	yield takeLatest(securityVersionEntityActions.deleteSecurityVersionFailure, deleteSecurityVersionFailure);
	yield takeLatest(securityVersionEntityActions.uploadSecurityVersion, onGetSecurityVersionUploadUrl);
	yield takeLatest(securityVersionEntityActions.uploadSecurityVersionSuccess, uploadSecurityVersionSuccess);
	yield takeLatest(securityVersionEntityActions.uploadSecurityVersionFailure, uploadSecurityVersionFailure);
	yield takeLatest(securityVersionEntityActions.createSecurityVersion, createSecurityVersion);
	yield takeLatest(securityVersionEntityActions.createSecurityVersionSuccess, createSecurityVersionSuccess);
	yield takeLatest(securityVersionEntityActions.createSecurityVersionFailure, createSecurityVersionFailure);
  yield takeLatest(securityVersionEntityActions.getSecurityVersionUploadUrlSuccess, onUploadSecurityVersion);
}
