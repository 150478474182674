/**
 * Create the store with dynamic reducers
 */
import { configureStore } from "@reduxjs/toolkit"
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';


export default function(initialState = {}, history) {
	const reduxSagaMonitorOptions = {};
	const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middleware = [sagaMiddleware, routerMiddleware(history)];
	const reducer = createRootReducer();

	const store = configureStore({
		reducer,
		middleware,
		preloadedState: initialState
	});

	// Extensions
	store.runSaga = sagaMiddleware.run;
	store.injectedReducers = {}; // Reducer registry
	store.injectedSagas = {}; // Saga registry

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	/* HMR DISABLE
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			store.replaceReducer(createRootReducer(store.injectedReducers));
		});
	}*/

	return store;
}
