import React from "react";
import styled from "styled-components";

const PreformattedJson = styled.pre`
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow: auto;
  white-space: pre;
  font-family: monospace;
  font-size: 0.875rem;
  max-height: 80vh;
`;

const ViewLicenseModal = ({ decryptedLicense }) => {
  const formatJSON = (data) => {
    
    const jsonData = typeof data === 'string' ? JSON.parse(data) : data;
    
    // Create a new json object with new order
    const reorderedData = {
      deviceType: jsonData.deviceType,
      deviceSerialNumber: jsonData.deviceSerialNumber,
      hwInfoSha: jsonData.hwInfoSha,
      license: jsonData.license
    };
    
    return JSON.stringify(reorderedData, null, 2);
  };
  return (
    <div>
      <PreformattedJson>
        {formatJSON(decryptedLicense)}
      </PreformattedJson>
    </div>
  );
};

export default ViewLicenseModal;