import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Button } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ConsentContent from '../containers/ConsentContentContainer';

const { Content, Header, Footer } = Layout;

const StyledHeader = styled(Header)`
	font-size: 1.5em;
	font-weight: 600;
  color: white;
  text-align: center;
`;


const StyledFooter = styled(Footer)`
  background-color: white;
	display: flex;
  place-content: space-between;
  padding: 24px 0;
`;

const PrevNextButtons = styled(Button)`
    border: none;
    background-color: inherit !important;
    color: #001529;
    font-weight: bold;
    font-size: 2em;
`;

const ModalLayout = ({ consents, confirmConsent}) => {
    const [currentConsentIndex, setCurrentConsentIndex] = useState(0);
    const currentConsentDocUrl = consents[currentConsentIndex]?.details?.downloadUrl;
    const isPrevButtonDisabled = currentConsentIndex === 0;
    const isNextButtonDisabled = currentConsentIndex === consents.length-1;

    const _onNextPressed =()=>{
      const nextIndex = currentConsentIndex+1;
      setCurrentConsentIndex(nextIndex);
    }

    const _onPrevPressed =()=>{
      const prevIndex = currentConsentIndex-1;
      setCurrentConsentIndex(prevIndex);
    }

    const _confirmConsent =()=>{
      confirmConsent(currentConsentIndex);
    }

  return (
    <Layout>
      <StyledHeader>
        <FormattedMessage {...messages.lumenisxTermsOfUse} />
      </StyledHeader>

      <Content>
        <ConsentContent downloadUrl={currentConsentDocUrl} onAccept={_confirmConsent} isAccepted={consents[currentConsentIndex].isAccepted}></ConsentContent>
      </Content>

      {!(isNextButtonDisabled && isPrevButtonDisabled) &&
      <StyledFooter>
        <PrevNextButtons onClick={_onPrevPressed} disabled={isPrevButtonDisabled}><ArrowLeftOutlined/></PrevNextButtons>
        <PrevNextButtons onClick={_onNextPressed} disabled={isNextButtonDisabled}><ArrowRightOutlined/></PrevNextButtons>
      </StyledFooter>
      }
    </Layout>
  );
};

const scope = 'ModalLayout';
const messages = defineMessages({
  lumenisxTermsOfUse: {
    id: `${scope}.lumenisxTermsOfUse`,
    defaultMessage: 'LumenisX Terms of Use',
  },
});

ModalLayout.propTypes = {
  intl: PropTypes.object.isRequired,
  consents: PropTypes.array.isRequired,
  confirmConsent: PropTypes.func.isRequired,
};

export default injectIntl(ModalLayout);
