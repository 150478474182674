import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { consentSelectors } from '../../../pages/Consent/modules/consent.slice';
import { appSelectors } from 'containers/App/modules/app.slice';
import { appActions } from 'containers/App/modules/app.slice';
import SideMenu from '../components/SideMenu';

const mapStateToProps = (state) => {
    const alarmsCount = appSelectors.selectActiveAlarmsCount(state);
    const confirmedConsentDownloadUrl = consentSelectors.getAllUserConfirmedConsentDownloadUrl(state);

	return {
	    alarmsCount,
		confirmedConsentDownloadUrl,
	}
};

const withConnect = connect(mapStateToProps, { ...appActions });

export default compose(
	withConnect
)(SideMenu);
