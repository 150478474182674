import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal, Spin, Typography, Button } from "antd";
import warningIcon from "images/warning.webp";

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const WarningModal = (props) => {
  const {
    onCancelModal,
    visible,
    spinning,
    title,
    onClickConfirm,
    onClickCancel,
  } = props;

  return (
    <Modal
      onCancel={onCancelModal}
      footer={null}
      visible={visible}
      maskClosable={false}
      centered={true}
      keyboard={false}
    >
      <Spin spinning={spinning}>
        <Container>
          <div
            style={{
              flex: 3,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={warningIcon}
              alt=""
              width="37%"
              height="100%"
              style={{ flex: 1 }}
            />
            <Title level={4} style={{ flex: 3 }}>
              {title}
            </Title>
          </div>

          <ButtonWrapper>
            <Button onClick={onClickCancel}>Cancel</Button>
            <div style={{ width: "10px" }} />
            <Button onClick={onClickConfirm}>Confirm</Button>
          </ButtonWrapper>
        </Container>
      </Spin>
    </Modal>
  );
};

WarningModal.propTypes = {
  onCancelModal: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  spinning: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default WarningModal;
