import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import ErrorBoundary from "components/ErrorBoundary";

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
    align-items: center;
`;

const HeaderText = styled.div`
	font-size: 2.1em;
	font-weight: 600;
    margin-bottom: 1em;
`;

const BodyText = styled.div`
	font-size: 1.3em;
    margin-bottom: 0.7em;
`;

const WarningModal = (props) => {
	const {
		headerText,
		warningText,
		areYouSureText,
        modalProps,
        visible,
        onOk,
        onCancel
    } = props;
    
	return (
		<ErrorBoundary>
			<Modal
				onCancel={onCancel}
				visible={visible}
				maskClosable={false}
				centered={true}
                keyboard={false}
                onOk={onOk}
				{...modalProps}
			>
				<ModalWrapper>
					<HeaderText>{headerText}</HeaderText>
					<BodyText>{warningText}</BodyText>
					<BodyText>{areYouSureText}</BodyText>
				</ModalWrapper>
			</Modal>
		</ErrorBoundary>
	);
};

export default WarningModal;
