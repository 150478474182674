import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Button } from "antd";
import { hasPermission, PERMISSIONS } from "services/PermissionsService";
import messages from "pages/Devices/components/messages";
import { deviceStatus, DEVICE_STATUS } from "./DeviceStatus";
import WarningModal from "./WarningModal";
const { Title } = Typography;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Spacer = styled.div`
  width: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
`;

const ButtonWithInfo = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {props.button}
      <Spacer />
      {props.info}
    </div>
  );
};

const DeviceActivationTab = (props) => {
  const [isActivationModalVisible, setIsActivationModalVisible] =
    useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  const closeActivationModal = () => {
    setIsActivationModalVisible(false);
  };

  const closeResetModal = () => {
    setIsResetModalVisible(false);
  };

  const isActivationModalLoading = Boolean(
    props.isDeactivateDeviceLoading || props.isReactivateDeviceLoading
  );
  const isResetModalLoading = Boolean(props.isResetDeviceLoading);

  const openActivationModal = () => {
    setIsActivationModalVisible(true);
  };

  const openResetModal = () => {
    setIsResetModalVisible(true);
  };

  const onClickActivationConfirm = () => {
    const deviceProps = {
      deviceSerialNumber: props.device.deviceSerialNumber,
      deviceType: props.device.deviceType.type,
    };
    const currentDeviceStatus = deviceStatus(props.device.status);
    if (currentDeviceStatus === DEVICE_STATUS.ACTIVE) {
      props.deactivateDevice(deviceProps);
    } else if (currentDeviceStatus === DEVICE_STATUS.DEACTIVATED) {
      props.reactivateDevice(deviceProps);
    }
  };

  const onClickResetConfirm = () => {
    const deviceProps = {
      deviceSerialNumber: props.device.deviceSerialNumber,
      deviceType: props.device.deviceType.type,
    };
    props.resetDevice(deviceProps);
  };

  useEffect(() => {
    if (!isActivationModalLoading) {
      closeActivationModal();
    }
  }, [isActivationModalLoading]);

  useEffect(() => {
    if (!isResetModalLoading) {
      closeResetModal();
    }
  }, [isResetModalLoading]);

  const isDeactivateBtnDisabled =
    deviceStatus(props.device.status) !== DEVICE_STATUS.ACTIVE ||
    !hasPermission(PERMISSIONS.UI_DISABLE_DEVICE);
  const isReactivateBtnDisabled =
    deviceStatus(props.device.status) !== DEVICE_STATUS.DEACTIVATED ||
    !hasPermission(PERMISSIONS.UI_DISABLE_DEVICE);

  const getWarningMessage = () => {
    const currentDeviceStatus = deviceStatus(props.device.status);
    const values = {
      deviceSerialNumber: props.device.deviceSerialNumber,
      deviceType: props.device.deviceType.type,
    };

    if (currentDeviceStatus === DEVICE_STATUS.ACTIVE) {
      return props.intl.formatMessage(messages.deactivateWarning, values);
    } else if (currentDeviceStatus === DEVICE_STATUS.DEACTIVATED) {
      return props.intl.formatMessage(messages.reactivateWarning, values);
    }
    return null;
  };

  return (
    <Container>
      <ButtonWrapper>
        <ButtonWithInfo
          button={
            <Button
              style={{ minWidth: 210 }}
              type="danger"
              onClick={openActivationModal}
              disabled={isDeactivateBtnDisabled}
            >
              {props.intl.formatMessage(messages["deactivateDevice.button"])}
            </Button>
          }
          info={props.intl.formatMessage(messages["deactivateDevice.info"])}
        />
        <div style={{ height: "30px" }}> </div>
        <ButtonWithInfo
          button={
            <Button
              style={{ minWidth: 210 }}
              type="primary"
              onClick={openActivationModal}
              disabled={isReactivateBtnDisabled}
            >
              {props.intl.formatMessage(messages["reactivateDevice.button"])}
            </Button>
          }
          info={props.intl.formatMessage(messages["reactivateDevice.info"])}
        />
        <div style={{ height: "30px" }}> </div>
        <ButtonWithInfo
          button={
            <Button
              style={{ minWidth: 210 }}
              type="danger"
              onClick={openResetModal}
            >
              {props.intl.formatMessage(messages["resetDevice.button"])}
            </Button>
          }
          info={props.intl.formatMessage(messages["resetDevice.info"])}
        />
      </ButtonWrapper>
      <Title level={4} style={{ marginTop: "20px" }}>
        Device Status: {deviceStatus(props.device.status)}
      </Title>
      <WarningModal
        onCancelModal={closeActivationModal}
        onClickConfirm={onClickActivationConfirm}
        onClickCancel={closeActivationModal}
        visible={isActivationModalVisible}
        spinning={isActivationModalLoading}
        title={getWarningMessage()}
      />
      <WarningModal
        onCancelModal={closeResetModal}
        onClickConfirm={onClickResetConfirm}
        onClickCancel={closeResetModal}
        visible={isResetModalVisible}
        spinning={isResetModalLoading}
        title={props.intl.formatMessage(messages.resetDeviceWarning, {
          deviceSerialNumber: props.device.deviceSerialNumber,
          deviceType: props.device.deviceType.type,
        })}
      />
    </Container>
  );
};

DeviceActivationTab.propTypes = {
  device: PropTypes.object,
  deactivateDevice: PropTypes.func.isRequired,
  reactivateDevice: PropTypes.func.isRequired,
  resetDevice: PropTypes.func.isRequired,
  isDeactivateDeviceLoading: PropTypes.bool.isRequired,
  isReactivateDeviceLoading: PropTypes.bool.isRequired,
  isResetDeviceLoading: PropTypes.bool.isRequired,
};

export default DeviceActivationTab;
