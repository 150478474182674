import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Select } from 'antd';
import { injectIntl } from 'react-intl';

const AlarmStateSelector = React.forwardRef((props, ref) => {
    const { stateOptions, ...rest } = props;

	return (
		<Select
		placeholder="Status: ALL"
		ref={ref}
		{...rest}>
		{
			stateOptions ? Object.values(stateOptions).map((stateOption, i) => {
				return (
					<Select.Option key={stateOption.id} value={stateOption.id}>{stateOption.name}</Select.Option>
				);
			}) : null
		}
	</Select>
	)
});

AlarmStateSelector.propTypes = {
	withAll: PropTypes.bool,
};

export default compose(
	connect(
		null,
		null,
		null,
		{ forwardRef: true }
    ),
    injectIntl
)(AlarmStateSelector);