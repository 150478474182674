import { connect } from "react-redux";
import { compose } from "redux";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import { injectIntl } from "react-intl";
import { swVersionEntityActions, swVersionEntitySelectors } from "containers/App/modules/entities/swVersions/swVersionEntity.slice";
import { swVersionsActions } from "../modules/swVersions.slice";
import SwVersionsForm from "../components/SwVersionsForm";

const mapStateToProps = (state, props) => {
	const isCreatePending = swVersionEntitySelectors.selectCreateSwVersionPending(state);

	return {
		isCreatePending
	}
};

const withConnect = connect(mapStateToProps, {
	...swVersionEntityActions,
	...swVersionsActions,
	...modalActions,
});

export default compose(withConnect, injectIntl)(SwVersionsForm);
