import { connect } from 'react-redux';
import { compose } from 'redux';
import ConsentContent from '../components/ConsentContent';
import {loginActions} from '../../LoginPage/modules/login.slice';
import {modalActions} from '../../../containers/ModalRoot/modules/modal.slice';

const mapStateToProps = (/*state*/) => ({
  
});

const mapActionCreators = {
    logout: loginActions.logout,
    hideModal: modalActions.hideModal,
  };
  

const withConnect = connect(mapStateToProps, mapActionCreators);

export default compose(
	withConnect
)(ConsentContent);
