/**
|--------------------------------------------------
| Action Types -
|     Should be - `<feature-name>/action`
|--------------------------------------------------
*/
export const UserManagementActionTypes = {
  USER_DATA_CHANGED: "userService/USER_DATA_CHANGED",
  RESET_USER_DATA: "userService/RESET_USER_DATA",
  SET_PERMISSIONS: "userService/SET_PERMISSIONS",
  RESET_PERMISSIONS: "userService/RESET_PERMISSIONS",  
};

/**
|--------------------------------------------------
| Action Creators
|--------------------------------------------------
*/

export const userDataChanged = nextParameters => {
  return { 
    type: UserManagementActionTypes.USER_DATA_CHANGED,
    value: nextParameters,
  };
};

export const resetUserData = () => {
  return {
    type: UserManagementActionTypes.RESET_USER_DATA,
  }
}

/**
|--------------------------------------------------
| We create an object that contains all the action
| and export it.
|--------------------------------------------------
*/
export const UserManagementAction = {
  userDataChanged,
  resetUserData,
};
