import React from "react";
import { Table } from "antd";
import { toLocalTime } from "utils/momentUtils";
import messages from "../messages";
const { Column } = Table;

const ServiceCommandsTable = (props) => {
  return (
    <Table dataSource={props.data} pagination={{ pageSize: 5 }} rowKey="id">
      <Column
        title={props.intl.formatMessage(messages.issueTimeColumn)}
        dataIndex={["creationTime"]}
        key="issueTime"
        render={(time) => toLocalTime(time)}
      ></Column>
      <Column
        title={props.intl.formatMessage(messages.statusColumn)}
        dataIndex={["status"]}
        key="status"
      ></Column>
      {props.columns.map((column) => (
        <Column
          title={props.intl.formatMessage(messages[column.title])}
          key={column.title}
          dataIndex={column.pathArray}
          render={column.renderFunction}
        ></Column>
      ))}
    </Table>
  );
};
export default ServiceCommandsTable;
