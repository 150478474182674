import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import {parseFetchAllRequest} from './lumenisXVersions.parsers';

const lumenisXVersionEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.LUMENISX_VERSIONS,
	parseFetchAllRequest
});

const { reducer, actions, selectors } = lumenisXVersionEntitySlice;
const {
	      fetchAll:        fetchLumenisXVersions,
	      fetchAllPending: fetchLumenisXVersionsPending,
	      fetchAllSuccess: fetchLumenisXVersionsSuccess,
	      fetchAllFailure: fetchLumenisXVersionsFailure,

	      fetchById: fetchLumenisXVersion,
	      fetchByIdPending: fetchLumenisXVersionPending,
	      fetchByIdSuccess: fetchLumenisXVersionSuccess,
	      fetchByIdFailure: fetchLumenisXVersionFailure,

	      create: createLumenisXVersion,
	      createPending: createLumenisXVersionPending,
	      createSuccess: createLumenisXVersionSuccess,
	      createFailure: createLumenisXVersionFailure,

	      update: updateLumenisXVersion,
	      updatePending: updateLumenisXVersionPending,
	      updateSuccess: updateLumenisXVersionSuccess,
	      updateFailure: updateLumenisXVersionFailure,
      } = actions;

export const lumenisXVersionEntityActions = {
	fetchLumenisXVersions,
	fetchLumenisXVersionsPending,
	fetchLumenisXVersionsSuccess,
	fetchLumenisXVersionsFailure,

	fetchLumenisXVersion,
	fetchLumenisXVersionPending,
	fetchLumenisXVersionSuccess,
	fetchLumenisXVersionFailure,

	createLumenisXVersion,
	createLumenisXVersionPending,
	createLumenisXVersionSuccess,
	createLumenisXVersionFailure,

	updateLumenisXVersion,
	updateLumenisXVersionPending,
	updateLumenisXVersionSuccess,
	updateLumenisXVersionFailure,
};


const {
	      selectAll:              selectLumenisXVersions,
	      selectFetchAllPending:  selectLumenisXVersionsPending,
	      createSelectByIdOrIds:  createSelectLumenisXVersionsByIdOrIds,
	      selectCreatePending:    selectCreateLumenisXVersionPending,
	      selectFetchByIdPending: selectFetchLumenisXVersionPending,
		  selectCreateError:    selectCreateLumenisXVersionError,
      } = selectors;


export const lumenisXVersionEntitySelectors = {
	selectLumenisXVersions,
	selectLumenisXVersionsPending,
	createSelectLumenisXVersionsByIdOrIds,
	selectCreateLumenisXVersionPending,
	selectFetchLumenisXVersionPending,
	selectCreateLumenisXVersionError,
};

export default reducer;