import { createSlice } from '@reduxjs/toolkit';
import { lumenisXVersionEntityActions } from 'containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.slice';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import {
	createResetGridReducer,
	createSetGridParamsReducer,
	createUpdateGridReducer,
	GRID_INITIAL_STATE,
	createSetGridParamsAction,
	createResetGridDataAction,
} from 'containers/Grid/grid.utils';


const LUMENISX_VERSIONS_STATE_KEY = 'lumenisXVersions';
const LUMENISX_VERSIONS_GRID_STATE_KEY = 'lumenisXVersionsGrid';
const setGridParams = createSetGridParamsAction(LUMENISX_VERSIONS_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(LUMENISX_VERSIONS_GRID_STATE_KEY);

export const INITIAL_STATE = {
	editedLumenisXVersionId:           undefined,
	editedLumenisXVersion:             undefined,
	[LUMENISX_VERSIONS_GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const lumenisXVersionsSlice = createSlice({
	name:          LUMENISX_VERSIONS_STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		clearEditedLumenisXVersion:
			(state, action) => {
				delete state.editedLumenisXVersionId;
				delete state.editedLumenisXVersion;
				delete state.potentialManagers;
			},
	},
	extraReducers: {
		[lumenisXVersionEntityActions.fetchLumenisXVersionSuccess]:              (state, action) => {
			const { data: { result }, metadata: {editable} } = action.payload;
			state.editedLumenisXVersionId = result;
		},

		//GRID
		[lumenisXVersionEntityActions.fetchLumenisXVersionsSuccess]: createUpdateGridReducer(LUMENISX_VERSIONS_GRID_STATE_KEY),
		[setGridParams]:                       createSetGridParamsReducer(LUMENISX_VERSIONS_GRID_STATE_KEY),
		[resetGridData]:                       createResetGridReducer(LUMENISX_VERSIONS_GRID_STATE_KEY),
	}
});


const getLumenisXVersionsState = state => state[LUMENISX_VERSIONS_STATE_KEY] || INITIAL_STATE;

const selectEditedLumenisXVersionId = createSelector(
	getLumenisXVersionsState,
	lumenisXVersionsState => _.get(lumenisXVersionsState, 'editedLumenisXVersionId')
);

const selectEditedLumenisXVersionPotentialManagersIds = createSelector(
	getLumenisXVersionsState,
	lumenisXVersionsState => _.get(lumenisXVersionsState, 'potentialManagers')
);

//todo fix
const selectPotentialLumenisXVersionsPending = createSelector(
	getLumenisXVersionsState,
	lumenisXVersionsState => _.get(lumenisXVersionsState, DEFAULT_LOADING_PROP)
);

const selectLumenisXVersionsGrid = createSelector(
	getLumenisXVersionsState,
	lumenisXVersionsState => {
		return lumenisXVersionsState[LUMENISX_VERSIONS_GRID_STATE_KEY]
	}
);

const selectLumenisXVersionIdsForPage = (state, props) => {
	const gridState = selectLumenisXVersionsGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const lumenisXVersionSelectors = {
	selectEditedLumenisXVersionId,
	selectEditedLumenisXVersionPotentialManagersIds,
	selectPotentialLumenisXVersionsPending,
	selectLumenisXVersionsGrid,
	selectLumenisXVersionIdsForPage
};

const {
	      clearEditedLumenisXVersion,
      } = lumenisXVersionsSlice.actions;
export const lumenisXVersionsActions = {
	clearEditedLumenisXVersion,
	setGridParams,
	resetGridData
};
export default lumenisXVersionsSlice.reducer;