import { put, call, takeLatest } from "redux-saga/effects";
import {
  showErrorToast,
  showSuccessToast,
} from "containers/App/modules/toasts/toasts.actions";
import BackendService from "services/BackendService";
import { deviceEntityActions } from "containers/App/modules/entities/devices/deviceEntity.slice";
import deviceMessages from "pages/Devices/components/messages";
import { api } from "containers/App/modules/async.saga";
import {
  createEntityFailure,
  createEntitySuccess,
  updateEntityFailure,
  errorToast,
  successToast,
  updateEntitySuccess,
} from "containers/App/modules/entities/entities.utils";
import entitySchemas from "containers/App/modules/api/schema";
import {
  parseFetchAllSuccess,
  parseFetchAssignedUsersSuccess,
  parseFetchDevicePropertiesSuccess,
  parseDeviceCommandsSuccess,
  parseSearchDeviceLogFiles,
} from "containers/App/modules/entities/devices/devices.parsers";
import { ENTITY_TYPES } from "../entities.constants";

function* fetchDeviceFiles(action) {
  const searchRequest = parseSearchDeviceLogFiles(action.payload);
  yield call(api, {
    apiFn: BackendService.fetchFiles,
    params: searchRequest,
    actions: [
      deviceEntityActions.fetchDeviceFilesPending,
      deviceEntityActions.fetchDeviceFilesSuccess,
      deviceEntityActions.fetchDeviceFilesFailure,
    ],
  });
}

function* generateLogs(action) {
  yield put(deviceEntityActions.fetchDeviceCommandsPending(true));

  yield call(api, {
    apiFn: BackendService.generateLogs,
    params: action.payload,
    actions: [
      deviceEntityActions.startCommandPending,
      deviceEntityActions.startCommandSuccess,
      deviceEntityActions.startCommandFailure,
    ],
  });

  const { deviceType, deviceSerialNumber } = action.payload;
  yield call(fetchDeviceCommands, {
    payload: { deviceType, deviceSerialNumber },
  });
}

function* installSecurityPatch(action) {
  yield call(api, {
    apiFn: BackendService.updateCommand,
    params: action.payload,
    actions: [
      deviceEntityActions.installSecurityPatchPending,
      deviceEntityActions.installSecurityPatchSuccess,
      deviceEntityActions.installSecurityPatchFailure,
    ],
  });
}

function* installSecurityPatchSuccess(action) {
  const installRequestParams = action.payload.requestParams;
  const payload = {deviceType: installRequestParams.deviceType, deviceSerialNumber: installRequestParams.deviceSerialNumber}
  yield put(deviceEntityActions.fetchDeviceCommands(payload));
}

function* fetchDeviceCommands(action) {
  yield put(deviceEntityActions.fetchDeviceCommandsPending(true));

  yield call(api, {
    apiFn: BackendService.fetchDeviceCommands,
    parseSuccessResponseFn: parseDeviceCommandsSuccess,
    params: action.payload,
    actions: [
      deviceEntityActions.fetchDeviceCommandsPending,
      deviceEntityActions.fetchDeviceCommandsSuccess,
      deviceEntityActions.fetchDeviceCommandsFailure,
    ],
  });
}

function* startCommand(action) {
  yield put(deviceEntityActions.startCommandPending(true));

  yield call(api, {
    apiFn: BackendService.startCommand,
    params: action.payload,
    actions: [
      deviceEntityActions.startCommandPending,
      deviceEntityActions.startCommandSuccess,
      deviceEntityActions.startCommandFailure,
    ],
  });

  const { deviceType, deviceSerialNumber } = action.payload;
  yield call(fetchDeviceCommands, {
    payload: { deviceType, deviceSerialNumber },
  });
}

function* startCommandSuccess() {
  yield call(successToast, deviceMessages.startCommandSuccess.defaultMessage);
}

function* startCommandFailure(action) {
  yield call(
    errorToast,
    deviceMessages.startCommandFailure.defaultMessage,
    action.payload.description
  );
}

function* updateCommand(action) {
  yield put(deviceEntityActions.updateCommandPending(true));

  yield call(api, {
    apiFn: BackendService.updateCommand,
    params: action.payload,
    actions: [
      deviceEntityActions.updateCommandPending,
      deviceEntityActions.updateCommandSuccess,
      deviceEntityActions.updateCommandFailure,
    ],
  });

  const { deviceType, deviceSerialNumber } = action.payload;
  yield call(fetchDeviceCommands, {
    payload: { deviceType, deviceSerialNumber },
  });
}

function* searchCommands(action) {
  yield call(api, {
    apiFn: BackendService.searchCommands,
    params: action.payload,
    actions: [
      deviceEntityActions.searchCommandsPending,
      deviceEntityActions.searchCommandsSuccess,
      deviceEntityActions.searchCommandsFailure,
    ],
  });
}

function* fetchDeviceCommandsFailure(action) {
  yield call(
    errorToast,
    action,
    "fetchDeviceCommands Failure",
    action.payload.description
  );
}

function* fetchDevices(action) {
  const apiFn = action.payload.composedGroupId
    ? BackendService.fetchDevicesWithGroups
    : BackendService.fetchDevices;

  yield call(api, {
    apiFn,
    parseSuccessResponseFn: parseFetchAllSuccess,
    schema: [entitySchemas.device],
    params: action.payload,
    actions: [
      deviceEntityActions.fetchDevicesPending,
      deviceEntityActions.fetchDevicesSuccess,
      deviceEntityActions.fetchDevicesFailure,
    ],
  });
}

function* createDevice(action) {
  const params = action.payload;
  yield call(api, {
    apiFn: BackendService.addDevice,
    params,
    actions: [
      deviceEntityActions.createDevicePending,
      deviceEntityActions.createDeviceSuccess,
      deviceEntityActions.createDeviceFailure,
    ],
  });
}

function* fetchAssignedUsers(action) {
  yield call(api, {
    apiFn: BackendService.fetchAssignedUsers,
    parseSuccessResponseFn: parseFetchAssignedUsersSuccess,
    params: action.payload,
    actions: [
      deviceEntityActions.fetchAssignedUsersPending,
      deviceEntityActions.fetchAssignedUsersSuccess,
      deviceEntityActions.fetchAssignedUsersFailure,
    ],
  });
}

function* updateAssignedUsers(action) {
  yield call(api, {
    apiFn: BackendService.updateAssignedUsers,
    params: action.payload,
    actions: [
      deviceEntityActions.updateAssignedUsersPending,
      deviceEntityActions.updateAssignedUsersSuccess,
      deviceEntityActions.updateAssignedUsersFailure,
    ],
  });
}

function* fetchCustomerDetails(action) {
  yield call(api, {
    apiFn: BackendService.fetchCustomerDetails,
    schema: entitySchemas.customerDetails,
    params: action.payload,
    actions: [
      deviceEntityActions.fetchCustomerDetailsPending,
      deviceEntityActions.fetchCustomerDetailsSuccess,
      deviceEntityActions.fetchCustomerDetailsFailure,
    ],
  });
}

function* updateCustomerDetails(action) {
  yield call(api, {
    apiFn: BackendService.updateCustomerDetails,
    params: action.payload,
    actions: [
      deviceEntityActions.updateCustomerDetailsPending,
      deviceEntityActions.updateCustomerDetailsSuccess,
      deviceEntityActions.updateCustomerDetailsFailure,
    ],
  });
}

function* onUpdateCustomerDetailsSuccess(action) {
  yield updateEntitySuccess(ENTITY_TYPES.DEVICES, action);
  //todo ugly, why? because API for fetch customer details to device type requires s/n and type
  // but I get the customerDetails ID on create success and have no service for that (supposed to update entity with ID also!)
  const {
    requestParams: { deviceSerialNumber, deviceType },
  } = action.payload;
  yield put(
    deviceEntityActions.fetchCustomerDetails({ deviceType, deviceSerialNumber })
  );
}

function* updateDeviceProperties(action) {
  yield call(api, {
    apiFn: BackendService.updateDeviceProperties,
    params: action.payload,
    actions: [
      deviceEntityActions.updateDevicePropertiesPending,
      deviceEntityActions.updateDevicePropertiesSuccess,
      deviceEntityActions.updateDevicePropertiesFailure,
    ],
  });
}

function* onUpdateDevicePropertiesSuccess(action) {
  yield updateEntitySuccess(ENTITY_TYPES.DEVICES, action);
  //todo ugly, why? because API for fetch customer details to device type requires s/n and type
  // but I get the customerDetails ID on create success and have no service for that (supposed to update entity with ID also!)
  const {
    requestParams: { deviceSerialNumber, deviceType },
  } = action.payload;
  yield put(
    deviceEntityActions.fetchDeviceProperties({
      deviceType,
      deviceSerialNumber,
    })
  );
}

function* fetchDeviceProperties(action) {
  yield call(api, {
    apiFn: BackendService.fetchDeviceProperties,
    parseSuccessResponseFn: parseFetchDevicePropertiesSuccess,
    schema: entitySchemas.device,
    params: action.payload,
    actions: [
      deviceEntityActions.fetchDevicePropertiesPending,
      deviceEntityActions.fetchDevicePropertiesSuccess,
      deviceEntityActions.fetchDevicePropertiesFailure,
    ],
  });
}

function* deactivateDevice(action) {
  yield call(api, {
    apiFn: BackendService.deactivateDevice,
    params: action.payload,
    actions: [
      deviceEntityActions.deactivateDevicePending,
      deviceEntityActions.deactivateDeviceSuccess,
      deviceEntityActions.deactivateDeviceFailure,
    ],
  });
  yield put(deviceEntityActions.fetchDeviceProperties(action.payload));
}

function* resetDevice(action) {
  yield call(api, {
    apiFn: BackendService.unregisterDevice,
    params: action.payload,
    actions: [
      deviceEntityActions.resetDevicePending,
      deviceEntityActions.resetDeviceSuccess,
      deviceEntityActions.resetDeviceFailure,
    ],
  });
  yield put(deviceEntityActions.fetchDeviceProperties(action.payload));
}

function* resetDeviceFailure(action) {
  yield put(
    showErrorToast({
      title: "Device reset failed",
      description: action.payload.description
    })
  );
}

function* resetDeviceSuccess(action) {
  yield put(
    showSuccessToast({
      title: "Device reset successfully",
    })
  );
}

function* reactivateDevice(action) {
  yield call(api, {
    apiFn: BackendService.reactivateDevice,
    params: action.payload,
    actions: [
      deviceEntityActions.reactivateDevicePending,
      deviceEntityActions.reactivateDeviceSuccess,
      deviceEntityActions.reactivateDeviceFailure,
    ],
  });
  yield put(deviceEntityActions.fetchDeviceProperties(action.payload));
}

function* deactivateDeviceSuccess(action) {
  yield put(
    showSuccessToast({
      title: "Device deactivated successfully",
    })
  );
}

function* deactivateDeviceFailure(action) {
  yield put(
    showErrorToast({
      title: "Device deactivation failed",
      description: action.payload.description
    })
  );
}

function* reactivateDeviceSuccess(action) {
  yield put(
    showSuccessToast({
      title: "Device reactivated successfully",
    })
  );
}

function* reactivateDeviceFailure(action) {
  yield put(
    showErrorToast({
      title: "Device reactivation failed",
      description: action.payload.description
    })
  );
}

function* changeLogMode(action) {
  yield call(api, {
    apiFn: BackendService.changeLogMode,
    params: action.payload,
    actions: [
      deviceEntityActions.changeLogModePending,
      deviceEntityActions.changeLogModeSuccess,
      deviceEntityActions.changeLogModeFailure,
    ],
  });
}

function* changeLogModeSuccess() {
  yield call(successToast, "Device change log mode successfully");
}

function* changeLogModeFailure(action) {
  yield call(
    errorToast,
    action,
    "Device change log mode failed",
    action.payload.description
  );
}

export default function* watchDevicesSaga() {
  yield takeLatest(deviceEntityActions.fetchDevices, fetchDevices);
  yield takeLatest(
    deviceEntityActions.fetchDeviceProperties,
    fetchDeviceProperties
  );
  yield takeLatest(deviceEntityActions.fetchAssignedUsers, fetchAssignedUsers);
  yield takeLatest(
    deviceEntityActions.fetchCustomerDetails,
    fetchCustomerDetails
  );

  yield takeLatest(
    deviceEntityActions.updateAssignedUsers,
    updateAssignedUsers
  );
  yield takeLatest(
    deviceEntityActions.updateAssignedUsersSuccess,
    updateEntitySuccess,
    ENTITY_TYPES.DEVICES
  );
  yield takeLatest(
    deviceEntityActions.updateAssignedUsersFailure,
    updateEntityFailure
  );
  yield takeLatest(
    deviceEntityActions.updateDeviceProperties,
    updateDeviceProperties
  );
  yield takeLatest(
    deviceEntityActions.updateDevicePropertiesSuccess,
    onUpdateDevicePropertiesSuccess
  );
  yield takeLatest(
    deviceEntityActions.updateDevicePropertiesFailure,
    updateEntityFailure
  );
  yield takeLatest(deviceEntityActions.createDevice, createDevice);
  yield takeLatest(
    deviceEntityActions.createDeviceSuccess,
    createEntitySuccess,
    ENTITY_TYPES.DEVICES
  );
  yield takeLatest(
    deviceEntityActions.createDeviceFailure,
    createEntityFailure
  );

  yield takeLatest(deviceEntityActions.deactivateDevice, deactivateDevice);
  yield takeLatest(deviceEntityActions.resetDevice, resetDevice);
  yield takeLatest(deviceEntityActions.resetDeviceFailure, resetDeviceFailure);
  yield takeLatest(deviceEntityActions.resetDeviceSuccess, resetDeviceSuccess);
  yield takeLatest(
    deviceEntityActions.deactivateDeviceSuccess,
    deactivateDeviceSuccess
  );
  yield takeLatest(
    deviceEntityActions.deactivateDeviceFailure,
    deactivateDeviceFailure
  );

  yield takeLatest(deviceEntityActions.reactivateDevice, reactivateDevice);
  yield takeLatest(
    deviceEntityActions.reactivateDeviceSuccess,
    reactivateDeviceSuccess
  );
  yield takeLatest(
    deviceEntityActions.reactivateDeviceFailure,
    reactivateDeviceFailure
  );
  yield takeLatest(deviceEntityActions.changeLogMode, changeLogMode);
  yield takeLatest(
    deviceEntityActions.changeLogModeSuccess,
    changeLogModeSuccess
  );
  yield takeLatest(
    deviceEntityActions.changeLogModeFailure,
    changeLogModeFailure
  );
  yield takeLatest(
    deviceEntityActions.fetchDeviceCommands,
    fetchDeviceCommands
  );
  yield takeLatest(
    deviceEntityActions.fetchDeviceCommandsFailure,
    fetchDeviceCommandsFailure
  );
  yield takeLatest(deviceEntityActions.startCommand, startCommand);
  yield takeLatest(deviceEntityActions.startCommandSuccess, startCommandSuccess);
  yield takeLatest(deviceEntityActions.startCommandFailure, startCommandFailure);
  yield takeLatest(deviceEntityActions.updateCommand, updateCommand);
  yield takeLatest(deviceEntityActions.searchCommands, searchCommands);
  yield takeLatest(deviceEntityActions.fetchDeviceFiles, fetchDeviceFiles);
  yield takeLatest(deviceEntityActions.generateLogs, generateLogs);
  yield takeLatest(deviceEntityActions.installSecurityPatch, installSecurityPatch);
  yield takeLatest(deviceEntityActions.installSecurityPatchSuccess, installSecurityPatchSuccess);


  yield takeLatest(
    deviceEntityActions.startCommandSuccess,
    startCommandSuccess
  );
  yield takeLatest(
    deviceEntityActions.startCommandFailure,
    startCommandFailure
  );

}
