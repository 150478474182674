export const GENERATE_LOGS_COMMAND_NAME = "GENERATE_LOGS";
export const PUBLISH_SECURITY_VERSION_COMMAND_NAME = "PUBLISH_SECURITY_VERSION";
export const REMOTE_INSTALLATION_COMMAND_STATUS_NAME = "REMOTE_INSTALLATION";
export const DOWNLOADED_COMMAND_STATUS_NAME = "DOWNLOADED";
export const GENERATE_LOGS_PARAMS = {
  RETENTION_DAYS: "retentionDays",
  LOG_STATES: "logStates",
  DAYS_AGO: "daysAgo",
};

export const COMMAND_STATES = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  ABORTED: "ABORTED",
};
