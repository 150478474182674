import React from 'react';
import moment from 'moment';
import EntityGrid, {loadData as entityGridLoadData} from 'containers/Grid/components/EntityGrid';
import AlarmsGridToolbar from './AlarmsGridToolbar';

const AlarmGrid = props => {
  const columns = [
    {
      key:       'timestamp',
      title:     'Date',
      dataIndex: 'timestamp',
      sorter:    true,
      render: timestamp => timestamp && moment(timestamp).format("D-MMM-YYYY hh:mma")
    },
    {
      key: "code",
      title: "Alarm Id",
      dataIndex: "code",
      sorter: true,
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      sorter: true,
    },
    {
      key: "state.name",
      title: "Status",
      dataIndex: "state",
      sorter: true,
      render: state => state.name,
    },
  ];

  const alarmsGrid = {
		...props.grid,
		params: {
		  ...props.grid.params,
		  additionalData: {
			  deviceSerialNumber: props.deviceSerialNumber,
			  deviceType: props.deviceType
		  }
		}
  }
   
  const refreshGrid = () => {
    entityGridLoadData(alarmsGrid, props.fetchDeviceAlarms);
  }

  const toolbarComponent = (
    <AlarmsGridToolbar
      resetGridData={props.resetGridData}
      setGridParams={props.setGridParams}
      grid={props.grid}
      refreshGrid={refreshGrid}
      showAddNew={false}
      onLoad={props.fetchAlarmStates}
      alarmStates={props.alarmStates}
    />
  );

  return (
    <EntityGrid
      toolbarComponent={toolbarComponent}
      grid={alarmsGrid}
      fetchData={props.fetchDeviceAlarms}
      fetchDataPending={props.isLoading}
      data={props.alarms}
      columns={columns}
      resetGridData={props.resetGridData}
      setGridParams={props.setGridParams}
      showAddNew={false}
    />
  );
  }
;

export default AlarmGrid;