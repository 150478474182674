import toastsSaga from './modules/toasts/toasts.saga';
import { ASYNC_ACTION_CREATORS, ASYNC_ACTION_TYPES } from './modules/async.actions';
import selectors from './modules/async.selectors';
import App from './containers/AppContainer';

export default {
    component: App,
    actions: ASYNC_ACTION_CREATORS,
    actionTypes: ASYNC_ACTION_TYPES,
    selectors,
    toastsSaga
};