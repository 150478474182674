import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { actions as v2cEntityActions } from 'containers/App/modules/entities/v2c/v2cEntity.slice';
import { createResetGridDataAction, createResetGridReducer, createSetGridParamsAction, createSetGridParamsReducer, GRID_INITIAL_STATE, createUpdateGridReducer } from 'containers/Grid/grid.utils';


const V2C_STATE_KEY = 'v2c';
const V2C_GRID_STATE_KEY = 'v2cGrid';
const setGridParams = createSetGridParamsAction(V2C_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(V2C_GRID_STATE_KEY);

export const INITIAL_STATE = {
	[V2C_GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const v2cHistorySlice = createSlice({
	name:          V2C_STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {},
	extraReducers: {
		//GRID
		[v2cEntityActions.fetchV2CHistorySuccess]: createUpdateGridReducer(V2C_GRID_STATE_KEY),
		[setGridParams]:                           createSetGridParamsReducer(V2C_GRID_STATE_KEY),
		[resetGridData]:                           createResetGridReducer(V2C_GRID_STATE_KEY),
	}
});


const getV2CHistoryState = state => state[V2C_STATE_KEY] || INITIAL_STATE;


const selectV2CHistoryGrid = createSelector(
	getV2CHistoryState,
	v2cHistoryState => {
		return v2cHistoryState[V2C_GRID_STATE_KEY]
	}
);

export const v2cHistorySelectors = {
	selectV2CHistoryGrid,
};

const {
} = v2cHistorySlice.actions;

export const v2cHistoryActions = {
	setGridParams,
	resetGridData
};
export default v2cHistorySlice.reducer;