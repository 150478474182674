import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { FormattedMessage } from 'react-intl';
import { loadData as entityGridLoadData } from 'containers/Grid/components/EntityGrid';
import messages from '../messages';

const RightAlignedDiv = styled.div`
  flex: 1;
  text-align: right;
`;

const MarginedButton = styled(Button)`
  margin-left: 1em;
`;

const size = "default";
const strListSplit = ", ";

const SecurityVersionMetadataPreviewModal = (props) => {
    const {
        securityVersionMetadataPreview,
        createSecurityVersion,
        hideModal,
        isCreatePending,
        grid,
        fetchSecurityVersions
    } = props;

    const onCreate = () => {
      createSecurityVersion({
          data: { version: securityVersionMetadataPreview.versionName, ...securityVersionMetadataPreview.checksums },
          refresh: refreshGrid
      });
    };

    const onCancel = () => {
        hideModal();
    };

    const refreshGrid = () => {
        entityGridLoadData(grid, fetchSecurityVersions);
    }

    const makeList = (items) => {
        return (
            <ul>
                {items.filter(item => item && item !== "").map(
                    (item, index) => <li key={index}>{item}</li>
                )}
            </ul>
        );
    }

    return (
        securityVersionMetadataPreview ?
            <>
                <p>
                    <b><FormattedMessage {...messages.versionName} />: </b>
                    {securityVersionMetadataPreview.versionName}<br />
                    <b><FormattedMessage {...messages.size} />: </b>
                    {securityVersionMetadataPreview.size}<br />
                    <b><FormattedMessage {...messages.numOfPatches} />: </b>
                    {securityVersionMetadataPreview.numOfPatches}<br />
                </p>
                <b><FormattedMessage {...messages.patches} />: </b><br />
                {makeList(securityVersionMetadataPreview.patches.split(strListSplit))}<br />
                <b><FormattedMessage {...messages.dependencies} />: </b><br />
                {makeList(securityVersionMetadataPreview.dependencies.split(strListSplit))}<br />
                <b><FormattedMessage {...messages.deviceTypes} />: </b><br />
                {makeList(securityVersionMetadataPreview.deviceTypes)}<br />
                <RightAlignedDiv>
                    <MarginedButton size={size} onClick={onCancel}>Cancel</MarginedButton>
                    <MarginedButton type="primary" size={size} onClick={onCreate} loading={isCreatePending}>
                        {isCreatePending ? "Creating..." : "Create Security Version"}
                    </MarginedButton>
                </RightAlignedDiv>
            </>
            : <FormattedMessage {...messages.getSecurityVersionMetadataPreviewError} />
    );
};

export default SecurityVersionMetadataPreviewModal;