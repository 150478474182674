import { connect } from "react-redux";
import { compose } from "redux";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import { injectIntl } from "react-intl";
import { securityVersionEntityActions, securityVersionEntitySelectors } from "containers/App/modules/entities/securityVersions/securityVersionEntity.slice";
import { securityVersionsActions } from "../modules/securityVersions.slice";
import UploadSecurityVersionsForm from "../components/UploadSecurityVersionForm";

const mapStateToProps = (state, props) => {
	const isUploadPending = securityVersionEntitySelectors.selectUploadSecurityVersionPending(state);

	return {
		isUploadPending
	}
};

const withConnect = connect(mapStateToProps, {
	...securityVersionEntityActions,
	...securityVersionsActions,
	...modalActions,
});

export default compose(withConnect, injectIntl)(UploadSecurityVersionsForm);
