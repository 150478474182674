import { connect } from 'react-redux';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';


import AlarmHistory from '../components/DeviceAlarmHistory/DeviceAlarmHistory';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { alarmsEntityActions, alarmsEntitySelectors } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import { deviceAlarmsEntityActions, deviceAlarmsEntitySelectors } from 'containers/App/modules/entities/deviceAlarms/deviceAlarmsEntity.slice';
import { alarmsActions, alarmsSelectors} from '../deviceAlarmHistory.slice';
import deviceAlarmsReducer from 'pages/Alarms/deviceAlarmHistory.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import schema from 'containers/App/modules/api/schema';
import _ from 'lodash';

const withReducer = injectReducer({
	key:     'deviceAlarms',
	reducer: deviceAlarmsReducer
});

const mapStateToProps = (state) => {
  const grid = alarmsSelectors.selectDeviceAlarmsGrid(state);
	const currentAlarmIds = _.get(state, 'deviceAlarms.deviceAlarmsGrid.currentIds');
	const alarms = dbSelectors.selectEntitiesDenormalized([schema.deviceAlarms], currentAlarmIds)(state);

	return {
		isLoading: deviceAlarmsEntitySelectors.selectDeviceAlarmsPending(state),
		alarms,
		alarmStates: alarmsEntitySelectors.selectAlarmStates(state),
		grid
	}
};

const withConnect = connect(mapStateToProps, { ...deviceAlarmsEntityActions, ...alarmsEntityActions, ...modalActions, ...alarmsActions });

export default compose(
  withReducer,
  withConnect
)(AlarmHistory);
