import { defineMessages } from "react-intl";

export const scope = "devices.page";

export default defineMessages({
  createDevice: {
    id: `${scope}.createDevice`,
    defaultMessage: "Create Device",
  },
  deviceSerialNumber: {
    id: `${scope}.deviceSerialNumber`,
    defaultMessage: "Device Serial Number",
  },
  clinicId: {
    id: `${scope}.clinicId`,
    defaultMessage: "Clinic ID",
  },
  clinicName: {
    id: `${scope}.clinicName`,
    defaultMessage: "Clinic Name",
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: "First Name",
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: "Last Name",
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: "Email",
  },
  street: {
    id: `${scope}.street`,
    defaultMessage: "Street",
  },
  streetNumber: {
    id: `${scope}.streetNumber`,
    defaultMessage: "Street Number",
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: "City",
  },
  zipCode: {
    id: `${scope}.zipCode`,
    defaultMessage: "Postal / Zip",
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: "Phone Number",
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: "Region / Country",
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: "State",
  },
  deviceType: {
    id: `${scope}.deviceType`,
    defaultMessage: "Device Type",
  },
  comments: {
    id: `${scope}.comments`,
    defaultMessage: "Comments",
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: "Activation Status",
  },
  creationTime: {
    id: `${scope}.creationTime`,
    defaultMessage: "Registration Time (UTC)",
  },
  lastUpdatedTime: {
    id: `${scope}.lastUpdatedTime`,
    defaultMessage: "Last Updated Time (UTC)",
  },

  activatedByUserEmail: {
    id: `${scope}.activatedByUserEmail`,
    defaultMessage: "Activating User Email",
  },
  iccid: {
    id: `${scope}.iccid`,
    defaultMessage: "ICCID",
  },
  imei: {
    id: `${scope}.imei`,
    defaultMessage: "IMEI",
  },
  applicationVersion: {
    id: `${scope}.applicationVersion`,
    defaultMessage: "Lumenis Application Version",
  },
  eventsKeysDictionaryVersion: {
    id: `${scope}.eventsKeysDictionaryVersion`,
    defaultMessage: "Events Dictionary Version",
  },
  systemStartByLocalClock: {
    id: `${scope}.systemStartByLocalClock`,
    defaultMessage: "Start Event Local Timestamp",
  },
  systemStartByInternetClock: {
    id: `${scope}.systemStartByInternetClock`,
    defaultMessage: "Start Event Internet Timestamp",
  },
  timestampsOffset: {
    id: `${scope}.timestampsOffset`,
    defaultMessage: "Local To Internet Timestamp Offset",
  },
  customMessage: {
    id: `${scope}.customMessage`,
    defaultMessage: "Custom Message",
  },
  lastMessageTimestamp: {
    id: `${scope}.lastMessageTimestamp`,
    defaultMessage: "Last Message At",
  },
  xplatformVersion: {
    id: `${scope}.xplatformVersion`,
    defaultMessage: "LumenisXPlatform Version",
  },
  commandsDictionaryVersion: {
    id: `${scope}.commandsDictionaryVersion`,
    defaultMessage: "Commands Dictionary Version",
  },
  activationType: {
    id: `${scope}.activationType`,
    defaultMessage: "Activation Type",
  },
  "deviceType.type": {
    id: `${scope}.deviceType.type`,
    defaultMessage: "Device Type",
  },
  hwInfoSha: {
    id: `${scope}.hwInfoSha`,
    defaultMessage: "Hardware Info Sha",
  },
  hwInfoShaUpdateTimestamp: {
    id: `${scope}.hwInfoShaUpdateTimestamp`,
    defaultMessage: "Hardware Info Sha Update Timestamp",
  },
  deactivateWarning: {
    id: `${scope}.deactivateWarning`,
    defaultMessage:
      "You are about to deactivate device {deviceSerialNumber} ({deviceType})",
    defaultValues: {
      deviceSerialNumber: "",
      deviceType: "",
    },
  },
  reactivateWarning: {
    id: `${scope}.reactivateWarning`,
    defaultMessage:
      "You are about to reactivate device {deviceSerialNumber} ({deviceType})",
    defaultValues: {
      deviceSerialNumber: "",
      deviceType: "",
    },
  },
  swLogsMode: {
    id: `${scope}.swLogsMode`,
    defaultMessage: "SW Log Mode",
  },
  lumXLogsMode: {
    id: `${scope}.lumXLogsMode`,
    defaultMessage: "LumenisX Log Mode",
  },
  sw: {
    id: `${scope}.sw`,
    defaultMessage: "SW",
  },
  lumenisX: {
    id: `${scope}.lumenisX`,
    defaultMessage: "LumenisX",
  },
  currentSwMode: {
    id: `${scope}.currentSwMode`,
    defaultMessage: "Current SW Logs Mode:",
  },
  currentLumMode: {
    id: `${scope}.currentLumMode`,
    defaultMessage: "Current LumenisX Logs Mode:",
  },
  downloadLogs: {
    id: `${scope}.downloadLogs`,
    defaultMessage: "Download Logs",
  },
  execute: {
    id: `${scope}.execute`,
    defaultMessage: "Apply",
  },
  logsNotSupported: {
    id: `${scope}.logsNotSupported`,
    defaultMessage: "The Logs feature is not supported for this device",
  },
  logsSupportMinimumVersion: {
    id: `${scope}.logsSupportMinimumVersion`,
    defaultMessage: "LumX version should be higher than ",
  },
  "connection.connectionName": {
    id: `${scope}.connection.connectionName`,
    defaultMessage: "Connection Name",
  },
  "connection.connectionType": {
    id: `${scope}.connection.connectionType`,
    defaultMessage: "Connection Type",
  },
  "connection.connectionSignal": {
    id: `${scope}.connection.connectionSignal`,
    defaultMessage: "Connection Signal",
  },
  "reactivateDevice.button": {
    id: `${scope}.activateDevice.button`,
    defaultMessage: "Reactivate Device",
  },
  "reactivateDevice.info": {
    id: `${scope}.activateDevice.info`,
    defaultMessage:
      "Reactivate a previously deactivated device. The device will be able to communicate with the cloud.",
  },
  "deactivateDevice.button": {
    id: `${scope}.deactivateDevice.button`,
    defaultMessage: "Deactivate Device",
  },
  "deactivateDevice.info": {
    id: `${scope}.deactivateDevice.info`,
    defaultMessage:
      "Set the device to be inactive. The device will not be able to communicate with the cloud.",
  },
  "resetDevice.button": {
    id: `${scope}.resetDevice.button`,
    defaultMessage: "Reset Device Communication",
  },
  "resetDevice.info": {
    id: `${scope}.resetDevice.info`,
    defaultMessage:
      "The device certificate will be revoked and its credentials too. The device will remain inactive until it re-registers with the cloud.",
  },
  resetDeviceWarning: {
    id: `${scope}.resetDeviceWarning`,
    defaultMessage:
      "The device {deviceSerialNumber} ({deviceType}) will be deactivated, and its credentials be reset.",
    defaultValues: {
      deviceSerialNumber: "",
      deviceType: "",
    },
  },
  abortButton: {
    id: `${scope}.abortButton`,
    defaultMessage: "Abort Command",
  },
  issueTimeColumn: {
    id: `${scope}.issueTimeColumn`,
    defaultMessage: "Issue Time",
  },
  statusColumn: {
    id: `${scope}.statusColumn`,
    defaultMessage: "Status",
  },
  Type: {
    id: `${scope}.lockType`,
    defaultMessage: "Type",
  },
  requestLogTypes: {
    id: `${scope}.requestLogTypes`,
    defaultMessage: "Request logs of types",
  },
  days: {
    id: `${scope}.days`,
    defaultMessage: "days",
  },
  retainFor: {
    id: `${scope}.retainFor`,
    defaultMessage: "Retain for",
  },
  requestLogsButton: {
    id: `${scope}.requestLogsButton`,
    defaultMessage: "Request Logs",
  },
  currentStatus: {
    id: `${scope}.currentStatus`,
    defaultMessage: "Current Status",
  },
  logs: {
    id: `${scope}.logs`,
    defaultMessage: "Logs",
  },
  "Retention Days": {
    id: `${scope}.retentionDaysColumn`,
    defaultMessage: "Retention Days",
  },
  "Logs since": {
    id: `${scope}.logsSinceColumn`,
    defaultMessage: "Logs Since",
  },
  noLogs: {
    id: `${scope}.noLogs`,
    defaultMessage: "No logs available",
  },
  logGenerationInProgress: {
    id: `${scope}.logGenerationInProgress`,
    defaultMessage: "Logs generation in progress",
  },
  logsReady: {
    id: `${scope}.logsReady`,
    defaultMessage: "Logs are ready to download",
  },
  forTheLast: {
    id: `${scope}.forTheLast`,
    defaultMessage: "For the last",
  },
  startCommandSuccess: {
    id: `${scope}.startCommandSuccess`,
    defaultMessage: "Device command sent successfully.",
  },
  startCommandFailure: {
    id: `${scope}.startCommandFailure`,
    defaultMessage: "Failed sending device command.",
  },
  securityPatch: {
    id: 'app.containers.DeviceServicesTab.securityPatch',
    defaultMessage: 'Security Patch',
  },
  installSecurityPatch: {
    id: 'app.containers.DeviceServicesTab.installSecurityPatch',
    defaultMessage: 'Install Security Patch',
  },
   // ... your existing messages ...
  
  // Security patch related messages
  securityVersion: {
    id: `${scope}.securityVersion`,
    defaultMessage: 'Security Version'
  },
  noSecurityVersion: {
    id: `${scope}.noSecurityVersion`,
    defaultMessage: 'No Version Available'
  },
  noLatestCommand: {
    id: `${scope}.noLatestCommand`,
    defaultMessage: 'No Latest Command'
  },
  commandStatus: {
    id: `${scope}.commandStatus`,
    defaultMessage: 'Command Status'
  },
  
  // Default statuses
  'status.IN_PROGRESS': {
    id: `${scope}.status.IN_PROGRESS`,
    defaultMessage: 'In Progress'
  },
  'status.ABORTED': {
    id: `${scope}.status.ABORTED`,
    defaultMessage: 'Aborted'
  },
  'status.COMPLETED': {
    id: `${scope}.status.COMPLETED`,
    defaultMessage: 'Completed'
  },
  'status.FAILED': {
    id: `${scope}.status.FAILED`,
    defaultMessage: 'Failed'
  },
  
  // Security patch specific statuses
  'status.DOWNLOADING': {
    id: `${scope}.status.DOWNLOADING`,
    defaultMessage: 'Downloading'
  },
  'status.DOWNLOADED': {
    id: `${scope}.status.DOWNLOADED`,
    defaultMessage: 'Downloaded'
  },
  'status.VALIDATE': {
    id: `${scope}.status.VALIDATE`,
    defaultMessage: 'Validating'
  },
  'status.TURN_OFF_UWF': {
    id: `${scope}.status.TURN_OFF_UWF`,
    defaultMessage: 'Turning Off UWF'
  },
  'status.UWF_OFF': {
    id: `${scope}.status.UWF_OFF`,
    defaultMessage: 'UWF Off'
  },
  'status.BACKUP': {
    id: `${scope}.status.BACKUP`,
    defaultMessage: 'Backing Up'
  },
  'status.INSTALLING': {
    id: `${scope}.status.INSTALLING`,
    defaultMessage: 'Installing'
  },
  'status.TURN_ON_UWF': {
    id: `${scope}.status.TURN_ON_UWF`,
    defaultMessage: 'Turning On UWF'
  },
  'status.UWF_ON': {
    id: `${scope}.status.UWF_ON`,
    defaultMessage: 'UWF On'
  },
  'status.RESTORE': {
    id: `${scope}.status.RESTORE`,
    defaultMessage: 'Restoring'
  },
  'status.PENDING': {
    id: `${scope}.status.PENDING`,
    defaultMessage: 'Pending'
  },
  'status.REMOTE_INSTALLATION': {
    id: `${scope}.status.REMOTE_INSTALLATION`,
    defaultMessage: 'Remote Installation'
  },
  'status.PENDING_COMPLETION': {
    id: `${scope}.status.PENDING_COMPLETION`,
    defaultMessage: 'Pending Completion'
  }
});
