import { defineMessages } from "react-intl";

export const scope = "groups";

export default defineMessages({
	update: {
		id: `${scope}.update`,
		defaultMessage: "Update",
	},
	create: {
		id: `${scope}.create`,
		defaultMessage: "Create",
	},
	groupName: {
		id: `${scope}.groupName`,
		defaultMessage: "Group Name",
	},
	deviceType: {
		id: `${scope}.deviceType`,
		defaultMessage: "Device Type / Family",
	},
	locations: {
		id: `${scope}.locations`,
		defaultMessage: "Locations",
	},
	deviceTypeNonSelectable: {
		id: `${scope}.deviceTypeNonSelectable`,
		defaultMessage: "In Group, only Device Family or Device Type are assignable",
	},
	publishUpdateButton: {
		id: `${scope}.publishUpdateButton`,
		defaultMessage: "Publish Update",
	},
	swVersion: {
		id: `${scope}.swVersion`,
		defaultMessage: "Software Version",
	},
	allowRemoteSoftwareUpdate: {
		id: `${scope}.allowRemoteSoftwareUpdate`,
		defaultMessage: "Allow Remote Software Update(*)",
	},
	lumXVersion: {
		id: `${scope}.lumXVersion`,
		defaultMessage: "LumenisX Version",
	},
	securityVersion: {
		id: `${scope}.securityVersion`,
		defaultMessage: "Security Version",
	},
	totalDevices: {
		id: `${scope}.totalDevices`,
		defaultMessage: "Total devices in group",
	},
	fullyUpdatedDevices: {
		id: `${scope}.fullyUpdatedDevices`,
		defaultMessage: "Number of fully updated devices",
	},
	latestLumxVersionDevices: {
		id: `${scope}.latestLumxVersionDevices`,
		defaultMessage: "Number of devices with the group's desired LumX version",
	},
	latestSoftwareVersionDevices: {
		id: `${scope}.latestSoftwareVersionDevices`,
		defaultMessage: "Number of devices with the group's desired SW version",
	},
	latestSecurityVersionDevices: {
		id: `${scope}.latestSecurityVersionDevices`,
		defaultMessage: "Number of devices with the group's desired security version",
	},
});
