import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

const RowDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: ${(props) => props.alignItems || "unset"};
`;

const UnderlinedText = styled.span`
	text-decoration: underline;
`;

const AssignAllRenderer = (props) => {
	const { onSelectAllClick } = props;

	return (
		<RowDiv alignItems="baseline">
			Assign
			<Button type="link" onClick={onSelectAllClick}>
				<UnderlinedText>All</UnderlinedText>
			</Button>
		</RowDiv>
	);
};

AssignAllRenderer.propTypes = {
	onSelectAllClick: PropTypes.func.isRequired,
};

export default AssignAllRenderer;
