/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */

import ProdConfig from "./ProdConfig";
import DevConfig from "./DevConfig";
import StgConfig from "./StgConfig";
import IntConfig from "./IntConfig";
import DevChinaConfig from "./DevChinaConfig";
import StgChinaConfig from "./StagingChinaConfig";
import ProdChinaConfig from "./ProdChinaConfig";
import env from "../env";

let APP_CONFIG = {};
switch (env.REACT_APP_ENV) {
  case "development":
    APP_CONFIG = DevConfig;
    break;
  case "staging":
    APP_CONFIG = StgConfig;
    break;
  case "integration":
    APP_CONFIG = IntConfig;
    break;
  case "production":
    APP_CONFIG = ProdConfig;
    break;
  case "development-china":
    APP_CONFIG = DevChinaConfig;
    break;
  case "staging-china":
    APP_CONFIG = StgChinaConfig;
    break;
  case "production-china":
    APP_CONFIG = ProdChinaConfig;
    break;
  default:
    APP_CONFIG = DevConfig;
    break;
}

export default APP_CONFIG;

export const mergeAppConfig = (newConf) => {
  APP_CONFIG = {
    ...APP_CONFIG,
    newConf,
  };
};
