import React from "react";
import PropTypes from "prop-types";
import { Tabs, Button } from "antd";
import messages from "./messages";
import styled from "styled-components";
import {
  GENERATE_LOGS_COMMAND_NAME,
  PUBLISH_SECURITY_VERSION_COMMAND_NAME,
  COMMAND_STATES,
} from "../general/CommandsConstants";
import { ReloadOutlined } from '@ant-design/icons';
import DeviceLogsTab from "pages/Devices/components/DeviceServices/DeviceLogsTab";
import SecurityPatchTab from "pages/Devices/components/DeviceServices/SecurityPatchTab";

const { TabPane } = Tabs;
const ReloadButton = styled(Button)`
  height: 32px;
  margin-top: 8px;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const DeviceServicesTab = (props) => {
  const {
    device,
    commands,
    commandsHistory,
    latestCommands,
    intl,
    startCommand,
    updateCommand,
    fetchDeviceCommands,
    fetchCommandsLoading,
    startCommandLoading,
    updateCommandsLoading,
    isInstallSecurityPatchPending,
    canManageLogs,
    generateLogs,
    installSecurityPatch,
    searchCommands,
    clearCommandState,
    loggedInUserId,
    fetchDeviceFiles,
    availableFiles,
  } = props;

  const deviceTypeFilter = {
    prop: "deviceType",
    values: device.deviceType.type,
  };
  const deviceSerialNumberFilter = {
    prop: "deviceSerialNumber",
    values: device.deviceSerialNumber,
  };

  const commandTypeFilter = (commandType) => ({
    prop: "commandType",
    values: commandType,
  });

  const startCommandCallback = (commandType, params) => {
    const deviceType = device.deviceType.type;
    const deviceSerialNumber = device.deviceSerialNumber;
    startCommand({
      deviceType,
      deviceSerialNumber,
      commandType,
      params,
      customMessage: commandType,
    });
  };

  const generateLogsCallback = (params) => {
    const deviceType = device.deviceType.type;
    const deviceSerialNumber = device.deviceSerialNumber;

    generateLogs({
      deviceType,
      deviceSerialNumber,
      params,
      customMessage: "",
    });
  };

  const installSecurityPatchCallback = (params) => {
    installSecurityPatch({
      ...params
    });
  };

  const fetchDeviceCommandsCallback = () => {
    const payload = {deviceType: device.deviceType.type, deviceSerialNumber: device.deviceSerialNumber}
    fetchDeviceCommands(payload)
  }

  const updateCommandCallback = (commandType, params, commandStatus) => {
    const deviceType = device.deviceType.type;
    const deviceSerialNumber = device.deviceSerialNumber;
    updateCommand({
      deviceType,
      deviceSerialNumber,
      commandType,
      params,
      status: commandStatus,
    });
  };

  return (
    <TabsContainer>
      <Tabs defaultActiveKey="logs" destroyInactiveTabPane>
        {canManageLogs && (
          <TabPane tab={intl.formatMessage(messages.logs)} key="logs">
            <DeviceLogsTab
              availableFiles={availableFiles}
              device={device}
              intl={intl}
              changeLogMode={props.changeLogMode}
              downloadDeviceFiles={props.downloadDeviceFiles}
              isDownloadDeviceFilesLoading={props.isDownloadDeviceFilesLoading}
              onMount={() => {
                fetchDeviceFiles({
                  filters: {
                    deviceId: device.id,
                  },
                });
                searchCommands({
                  limit: 5,
                  filter: [
                    deviceSerialNumberFilter,
                    deviceTypeFilter,
                    commandTypeFilter(GENERATE_LOGS_COMMAND_NAME),
                  ],
                });
              }}
              command={commands[GENERATE_LOGS_COMMAND_NAME]}
              history={commandsHistory[GENERATE_LOGS_COMMAND_NAME]}
              generateLogs={(params) => {
                generateLogsCallback({
                  userId: loggedInUserId,
                  ...params,
                });
              }}
              onUnmount={() => clearCommandState(GENERATE_LOGS_COMMAND_NAME)}
            ></DeviceLogsTab>
          </TabPane>
        )}
        <TabPane tab={intl.formatMessage(messages.securityPatch)} key="security">
          <SecurityPatchTab
            device={device}
            latestCommand={latestCommands[PUBLISH_SECURITY_VERSION_COMMAND_NAME]}
            intl={intl}
            isSecurityPatchRequestPending={isInstallSecurityPatchPending}
            updateCommand={(params) => 
              installSecurityPatchCallback(params)
            }
          />
        </TabPane>
      </Tabs>
      <ReloadButton onClick={fetchDeviceCommandsCallback}>
        < ReloadOutlined />
      </ReloadButton>
    </TabsContainer>
  );
};
DeviceServicesTab.propTypes = {
  device: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};
export default DeviceServicesTab;
