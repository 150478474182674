import React, {useEffect} from 'react';

const ConnectWise = () => {
	useEffect(() => {
		window.open("https://cw.lumenis.com/", "_blank")
	})
	// useEffect is without [] so it will render on every update
	// because we want that for each click on Connect Wise option on the side menu a new tab will open
	return <div />
};

export default ConnectWise;