import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { deviceAlarmsEntityActions } from 'containers/App/modules/entities/deviceAlarms/deviceAlarmsEntity.slice';
import { createResetGridDataAction, createResetGridReducer, createSetGridParamsAction, createSetGridParamsReducer, createUpdateGridReducer, GRID_INITIAL_STATE } from 'containers/Grid/grid.utils';

const STATE_KEY = 'deviceAlarms';
const GRID_STATE_KEY = 'deviceAlarmsGrid';
const setGridParams = createSetGridParamsAction(GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(GRID_STATE_KEY);

export const INITIAL_STATE = {
	[GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const slice = createSlice({
	name:          STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
	},
	extraReducers: {
		//GRID
		[deviceAlarmsEntityActions.fetchDeviceAlarmsSuccess]: createUpdateGridReducer(GRID_STATE_KEY),
		[setGridParams]:                                      createSetGridParamsReducer(GRID_STATE_KEY),
		[resetGridData]:                                      createResetGridReducer(GRID_STATE_KEY),
	}
});

const getState = state => state[STATE_KEY] || INITIAL_STATE;

const selectDeviceAlarmsGrid = createSelector(
	getState,
	deviceAlarmsState => {
		return deviceAlarmsState[GRID_STATE_KEY]
	}
);

const selectAlarmIdsForPage = (state, props) => {
	const gridState = selectAlarmsGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const alarmsSelectors = {
	selectDeviceAlarmsGrid,
	selectAlarmIdsForPage,
};

export const alarmsActions = {
	setGridParams,
	resetGridData
};
export default slice.reducer;