import { createSlice } from '@reduxjs/toolkit';
import { licenseEntityActions } from 'containers/App/modules/entities/licenses/licenseEntity.slice';
import _ from 'lodash';
import { createSelector } from 'reselect';

import {
	createResetGridReducer,
	createSetGridParamsReducer,
	createUpdateGridReducer,
	GRID_INITIAL_STATE,
	createSetGridParamsAction,
	createResetGridDataAction,
} from 'containers/Grid/grid.utils';


const LICENSES_STATE_KEY = 'licenses';
const LICENSES_GRID_STATE_KEY = 'licensesGrid';
const DECRYPTED_LICENSE_STATE_KEY = 'decryptedLicense';
const setGridParams = createSetGridParamsAction(LICENSES_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(LICENSES_GRID_STATE_KEY);

export const INITIAL_STATE = {
	[LICENSES_GRID_STATE_KEY]: GRID_INITIAL_STATE,
	[DECRYPTED_LICENSE_STATE_KEY]: ""
};

const licensesSlice = createSlice({
	name: LICENSES_STATE_KEY,
	initialState: INITIAL_STATE,
	reducers: {
		setDecryptedLicense: (state, action) => {
			state[DECRYPTED_LICENSE_STATE_KEY] = action.payload;
		},
	},
	extraReducers: {
		//licenses GRID
		[licenseEntityActions.fetchLicensesSuccess]: createUpdateGridReducer(LICENSES_GRID_STATE_KEY),
		[setGridParams]: createSetGridParamsReducer(LICENSES_GRID_STATE_KEY),
		[resetGridData]: createResetGridReducer(LICENSES_GRID_STATE_KEY),
	}
});

const getLicenseState = state => state[LICENSES_STATE_KEY] || INITIAL_STATE;

const selectLicensesGrid = createSelector(
	getLicenseState,
	licensesState => {
		return licensesState[LICENSES_GRID_STATE_KEY]
	}
);

const selectDecryptedLicense = createSelector(
	getLicenseState,
	licensesState => {
		return licensesState[DECRYPTED_LICENSE_STATE_KEY]
	}
)

const selectLicenseIdsForPage = (state, props) => {
	const gridState = selectLicensesGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const licenseSelectors = {
	selectLicensesGrid,
	getLicenseState,
	selectLicenseIdsForPage,
	selectDecryptedLicense
};

const { setDecryptedLicense } = licensesSlice.actions;

export const licensesActions = {
	setGridParams,
	resetGridData,
	setDecryptedLicense,
};

export default licensesSlice.reducer;